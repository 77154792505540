// Chart Constants
export const RIGHT = "RIGHT"
export const LEFT = "LEFT"

// Chart Types
export const PEDAGOGIC = "pedagogic"
export const REGULAR = "regular"

export const CHART_TYPES = [PEDAGOGIC, REGULAR]

// Usual constants
export const VALUE = "value"
export const NAME = "name"
export const QUARTER = "quarter"
export const MID_ANGLE = "mid_angle"

// Null Constants
export const NS_NR = "NS/NR"
