import React from 'react';

import * as con from "../Constants"
import AreasMap from '../components/maps/AreasMap';
import { getApplicationParameter } from "../store/actions/applicationParameters";
import { Link } from "react-router-dom"


// static
import logoSigeti from "../static/img/sigeti.png"
import offlineMap from "../static/img/offlineMap.png"


function AreaCalculation() {

  let isOnline = getApplicationParameter(con.IS_ONLINE)

  return (
    <div className='column-container'>
      <div className='column-container'>
        <div className='row-container'>
          <div style={{height : "65px", width:"120px"}}>
            <Link to={con.PATH_HOME}>
              <img src={logoSigeti} height="100%" alt="logo sigeti"/>
            </Link>
          </div>            
          <h2>Cálculo de áreas</h2>
        </div>
      </div>
    <div>
        {isOnline ? <AreasMap /> : <div className='row-container'><img className="offlineMapImg" style={{width: "50%"}} src={offlineMap} alt="offline map placeholder"/></div>}
    </div>
    </div>
  );
}

export default AreaCalculation