import React, { useEffect, useMemo, useRef } from 'react'


import PieChart from '../../containers/d3Charts/PieChart'

import * as con from "../../Constants"
import { RefApplicationParameterHook } from '../hooks/ApplicationParamatersHook'

import CommunityDataLoader from '../data/CommunityDataLoader'
import { educationGroupSortingFunction, translateEducationGroup } from '../../utils/translateFunctions'


function EducationPieChartByCommunity({includeTerritoryInTitle}) {



        // Object Reference
        const objectReference = useRef()

        
        // Display Mode
        const displayMode = RefApplicationParameterHook(con.DISPLAY_MODE)

        // Selected Community and Territory
        const communityId = RefApplicationParameterHook(con.SELECTED_COMMUNITY_ID)

        // Initial Parameters
        const  parameters = useMemo(() => {return({ 
            nameExtractor : d => translateEducationGroup(d.grupo_escolar),
            valueExtractor : d => d.total,
            sortingFunction : (a,b) => educationGroupSortingFunction(a.grupo_escolar, b.grupo_escolar)      
        })}, [])
        
       
        // Territory data
        const dataCommunities = CommunityDataLoader(con.DATASET_EDUCATION_COMMUNITY)


        useEffect(() => {
            
            if(dataCommunities.length > 0) 
                PieChart.build(objectReference, dataCommunities, parameters, displayMode)

        }, [ dataCommunities, communityId, parameters, displayMode])

        

        return (
            <div style={{marginLeft : "2%", marginRight : "2%"}}>
                {dataCommunities.length > 0 && <svg ref={objectReference} />}
            </div>
        );

}

EducationPieChartByCommunity.defaultProps = {
    includeTerritoryInTitle : false
}


export default EducationPieChartByCommunity