import React from 'react';

import * as con from "../Constants"

import LenguasNetworkChart from '../components/charts/LenguasNetworkChart';
import DeforestationMap from '../components/maps/DeforestationMap';
import LenguasDistributionChart from '../components/charts/LenguasDistributionChart'
import CommunityBarChartByLanguage from '../components/charts/CommunityBarChartByLanguage';
import { TerritorySelect, CommunitySelect } from '../components/parameters/applicationParameters';
import LegendItem from "../components/basicComponents/LegendItem"
import DeforestationChangeLineChart from '../components/charts/DeforestationChangeLineChart';
import LanguagesPieChartByCommunity from '../components/charts/LanguagesPieChartByCommunity';
import { NO_COMMUNITY_SELECTED, SELECTED_COMMUNITY_ID } from '../Constants';
import LanguagesPieChartByTerritory from '../components/charts/LanguagesPieChartByTerritory';
import { RefApplicationParameterHook } from '../components/hooks/ApplicationParamatersHook';
import { Link } from "react-router-dom"
import { DahsboardDisplayModeSelect } from "../components/parameters/applicationParameters"
import { getApplicationParameter } from "../store/actions/applicationParameters";

// static
import logoSigeti from "../static/img/sigeti.png"
import offlineMap from "../static/img/offlineMap.png"

function LenguasDeforestacionView() {

  // Ref Community
  const communityId = RefApplicationParameterHook(SELECTED_COMMUNITY_ID)
  let isOnline = getApplicationParameter(con.IS_ONLINE)

  return (
    <div className='column-container' >
      <div className='column-container'>
        <div className='row-container'>
          <div style={{height : "65px", width:"120px"}}>
            <Link to={con.PATH_HOME}>
              <img src={logoSigeti} height="100%" alt="logo sigeti"/>
            </Link>
          </div>            
          <h2>Pánel interactivo de lenguas y deforestación</h2>
        </div>
        <DahsboardDisplayModeSelect />
      </div>
      <div className='row-container' id="outline">
        <LegendItem color={"var(--lenguas-occidentales-color)"} name="Lenguas occidentales" size="10"/>
        <LegendItem color={"var(--lenguas-tradicionales-color)"} name="Lenguas tradicionales"  size="10"/>
        <LegendItem color={"var(--women-color)"} name="Mujeres" size="10"/>
        <LegendItem color={"var(--men-color)"} name="Hombres" size="10"/>
      </div>
      <div className='row-container' id='outline'>
        <div className='stretch-1-12' id='rotated-label'>
              Selección por territorio o comunidad
        </div>
        <div className='stretch-4-12' id='map'>
          <div className='column-container'>
            {isOnline ? <DeforestationMap /> : <img className="offlineMapImg" src={offlineMap} alt="offline map placeholder"/>}
            <TerritorySelect />
            <CommunitySelect />
          </div>
        </div>
        <div className='stretch-7-12' id='graphs'>
          <div className='column-container'>
              <LenguasDistributionChart includeTerritoryInTitle={true} />
            <div className='row-container'>
            <div className="stretch-8-12">
              <DeforestationChangeLineChart includeTerritoryInTitle={true}/>
            </div>
            <div className="stretch-4-12">
              {
                communityId === NO_COMMUNITY_SELECTED 
                ? <LanguagesPieChartByTerritory />
                : <LanguagesPieChartByCommunity />
              }   
            </div>           
            </div>
          </div>
        </div>
      </div>
      <div className='row-container' id='outline'>
        <div className='stretch-1-12' id='rotated-label'>
          Selección por lengua
        </div>
        <div className='stretch-4-12' style={{margin: '5px'}}>
          <LenguasNetworkChart includeTerritoryInTitle={true} />
        </div>
        <div className='stretch-7-12'>
          <CommunityBarChartByLanguage/>
        </div>
      </div>
    </div>
  );
}

export default LenguasDeforestacionView