import React from 'react'
import { Card, Elevation } from "@blueprintjs/core";

import "./CustomCard.css"

function CustomCard({title, value, color}) {



  return (
    <Card interactive={true} 
        elevation={Elevation.ONE} 
        style={{backgroundColor : color}}
        className="customCard">
        <h2 style={{textAlign : 'center', marginBottom : "0.5vh", marginTop : "0.5vh"}}>{title}</h2>
        <h3 style={{textAlign : 'center'}}>{value}</h3>        
    </Card>
  )
}

export default CustomCard