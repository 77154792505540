import React, { useEffect, useMemo, useRef } from 'react'


import HorizonatlBarChart from '../../containers/d3Charts/HorizonatlBarChart'

import * as con from "../../Constants"
import { RefApplicationParameterHook } from '../hooks/ApplicationParamatersHook'

import TerritoryDataLoader from '../data/TerritoryDataLoader'


function EthnicBarChartByTerritory({includeTerritoryInTitle}) {


        // Object Reference
        const objectReference = useRef()

        // Selected Community and Territory
        const territoryId = RefApplicationParameterHook(con.SELECTED_TERRITORY_ID)

        // Initial Parameters
        const  parameters = useMemo(() => {return({ 
            x : d => d.pueblo,
            y : d => d.total,
            group1 : 'total_hombres',
            group2 : 'total_mujeres',
            total : 'total',
            variable : 'pueblo',
            geoIdentifier : 'territorio',
            xLabel : "Pueblo",
            yLabel : "Personas",
            title : "Distribución de Pueblos"
        })},  [])
        
       
        // Territory data
        const dataTerritories = TerritoryDataLoader(con.DATASET_ETHNICITY_DATA_TERRITORY)

        // Display Mode
        const displayMode = RefApplicationParameterHook(con.DISPLAY_MODE)


        useEffect(() => {
            
            if(dataTerritories.length > 0) 
                HorizonatlBarChart.build(objectReference, dataTerritories, parameters, displayMode)

        }, [dataTerritories, territoryId, parameters, displayMode])

        

        return (
            <div style={{width : "100%"}}>
                {dataTerritories.length > 0 && <svg ref={objectReference} />}
            </div>
        );

}

EthnicBarChartByTerritory.defaultProps = {
    includeTerritoryInTitle : false
}


export default EthnicBarChartByTerritory