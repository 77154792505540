import React, { useEffect, useState } from 'react';


import * as con from '../../Constants'
import { downloadExcelFromArray, downloadJSON } from '../../utils/dataFunctions';
import { DatasetSelect } from '../parameters/applicationParameters';
import { RefApplicationParameterHook,  } from '../hooks/ApplicationParamatersHook';
import { RefApplicationArrayDataHook } from '../hooks/ApplicationDataHook';
import selectedDataSets from '../../database/selectedDataSets.json' 


function DataDownloader() {

    const selectedDataset = RefApplicationParameterHook(con.SELECTED_DATASET)
    const [isDownloading, setIsDownloading] = useState(false)
    const data = RefApplicationArrayDataHook(selectedDataset)

    const download = () => {
        setIsDownloading(true)
        let type = selectedDataSets[selectedDataset][con.FORMAT]
        switch(type){
            case 'JSON':
                downloadExcelFromArray(data, selectedDataset)
                break;
            case 'GEOJSON':
                downloadJSON(data, selectedDataset)
                break;
        }
        
        setIsDownloading(false)
    }

    return (
        <div className='column-container'>
            <DatasetSelect />
            {isDownloading ?
                <div> 
                Descargando {selectedDataset}
                <button style={{width: 'fit-content', alignSelf: 'center'}} className='disabled'>Descargar</button>
                </div> : 
                <button style={{width: 'fit-content', alignSelf: 'center'}} className='submit' onClick={() => download()}>Descargar</button>}
        </div>
    );
}

export default DataDownloader