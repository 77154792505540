// Local Store
// Data is imported as JSON during build
// Avoids CORS Error when using the app from build offline

import * as con from "../../Constants"

// Loads all files
import lenguasNodes from "../data/censo_632.lenguas.json"
import lenguasEdges from "../data/censo_632.red_lenguas.json"
import lenguasAgeHistogram from "../data/censo_632.histograma_lenguas_por_edad.json"
import lenguasAgeDistributionCommunity from "../data/censo_632.distribucion_lenguas_por_edad_y_comunidad.json"
import lenguasAgeDistributionTerritory from "../data/censo_632.distribucion_lenguas_por_edad_y_territorio.json"
import ageDistribution from "../data/censo_632.distribucion_edad_por_comunidad.json"
import sexDistribution from "../data/censo_632.distribucion_sexo_por_comunidad.json"
import ethnicityByCommunity from "../data/censo_632.pueblos_por_comunidad.json"
import ethnicityByTerritory from "../data/censo_632.pueblos_por_territorio.json"
import generalDataCommunity from "../data/censo_632.conteos_generales_por_comunidad.json"
import generalDataTerritory from "../data/censo_632.conteos_generales_por_territorio.json"
import availableCommunities from "../data/censo_632.comunidades_por_territorio.json"
import availableTerritories from "../data/censo_632.territorios.json"
import educationTerritories from "../data/censo_632.escolaridad_por_territorio_resumido.json"
import educationCommunities from "../data/censo_632.escolaridad_por_comunidad_resumido.json"
import languagesTerritories from "../data/censo_632.distribucion_lenguas_por_territorio.json"
import languagesCommunities from "../data/censo_632.distribucion_lenguas_por_comunidad.json"
import deforestacionCommunities from "../data/deforestacion.deforestacion_por_comunidad.json"


const DB_TO_FILE_MAP = {
    [con.DATASET_AVAILABLE_TERRITORIES] : availableTerritories,
    [con.DATASET_AVAILABLE_COMMUNITIES] : availableCommunities,
    [con.DATASET_GENERAL_DATA_COMMUNITY] : generalDataCommunity,      
    [con.DATASET_GENERAL_DATA_TERRITORY] : generalDataTerritory,     
    [con.DATASET_ETHNICITY_DATA_COMMUNITY] : ethnicityByCommunity, 
    [con.DATASET_ETHNICITY_DATA_TERRITORY] : ethnicityByTerritory, 
    [con.DATASET_SEX_DISTRIBUTION] : sexDistribution,
    [con.DATASET_AGE_DISTRIBUTION] : ageDistribution,
    [con.DATASET_EDUCATION_TERRITORY] : educationTerritories,
    [con.DATASET_EDUCATION_COMMUNITY] : educationCommunities,
    [con.DATASET_LANGUAGES_TERRITORY] :languagesTerritories,
    [con.DATASET_LANGUAGES_COMMUNITY] :languagesCommunities,
    [con.DATASET_LENGUAS_AGE_DISTRIBUTION_COMMUNITY] : lenguasAgeDistributionCommunity,
    [con.DATASET_LENGUAS_AGE_DISTRIBUTION_TERRITORY] : lenguasAgeDistributionTerritory,
    [con.DATASET_LENGUAS_AGE_HISTOGRAM] : lenguasAgeHistogram,
    [con.DATASET_LENGUAS_EDGES] : lenguasEdges,
    [con.DATASET_LENGUAS_NODES] : lenguasNodes,
    [con.DATASET_DEFORESTATION_COMMUNITY] : deforestacionCommunities,
}

// Main Class
class LocalDataLoader {

    // Checks if the loader can load a specific dataset
    static canLoadDataSet = (dataSetId) => {
        return(dataSetId in DB_TO_FILE_MAP)
    }   


    // Loads a single database into store
    static loadSingleDataSetIntoStore = async (dataSetId) =>
    {
        return(DB_TO_FILE_MAP[dataSetId])
    }

}

export default LocalDataLoader