import React, { useEffect, useMemo, useRef } from 'react'


import HorizonatlBarChart from '../../containers/d3Charts/HorizonatlBarChart'

import * as con from "../../Constants"
import { RefApplicationParameterHook } from '../hooks/ApplicationParamatersHook'

import TerritoryDataLoader from '../data/TerritoryDataLoader'


function FamiliesBarChartByTerritory({includeTerritoryInTitle}) {


        // Object Reference
        const objectReference = useRef()

        // Selected Community and Territory
        const territoryId = RefApplicationParameterHook(con.SELECTED_TERRITORY_ID)

        // Initial Parameters
        const  parameters = useMemo(() => {return({ 
            x : d => d.nombre_comunidad,
            y : d => d.numero_familias,
            group1 : 'numero_familias',
            group2 : '',
            colorGroup1 : "var(--families-color)",
            total : 'numero_familias',
            variable : 'nombre_comunidad',
            geoIdentifier : 'territorio',
            xLabel : "Comunidad",
            yLabel : "Familias",
            title : "Distribución de Familias",
            marginBottom : 200,
        })},  [])
        
       
        // Territory data
        const dataTerritories = TerritoryDataLoader(con.DATASET_GENERAL_DATA_COMMUNITY)

        // Display Mode
        const displayMode = RefApplicationParameterHook(con.DISPLAY_MODE)


        useEffect(() => {
            
            if(dataTerritories.length > 0) 
                HorizonatlBarChart.build(objectReference, dataTerritories, parameters, displayMode)

        }, [dataTerritories, territoryId, parameters, displayMode])

        

        return (
            <div style={{width : "100%"}}>
                {dataTerritories.length > 0 && <svg ref={objectReference} />}
            </div>
        );

}

FamiliesBarChartByTerritory.defaultProps = {
    includeTerritoryInTitle : false
}


export default FamiliesBarChartByTerritory