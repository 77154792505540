import React, { useRef, useMemo, useEffect } from 'react';

import HorizonatlBarChart from '../../containers/d3Charts/HorizonatlBarChart'
import * as con from '../../Constants'
import { RefApplicationParameterHook } from '../hooks/ApplicationParamatersHook'
import { getCommunityByID, getTerritoryByID } from '../../utils/dataFunctions'
import AgnosticDataLoader from '../data/AgnosticDataLoader';

function LenguasDistributionChart({includeTerritoryInTitle}) {
    
         // Object Reference
         const objectReference = useRef()

         // Display Mode
         const displayMode = RefApplicationParameterHook(con.DISPLAY_MODE)
 
         // Selected Community and Territory
         const communityId = RefApplicationParameterHook(con.SELECTED_COMMUNITY_ID)
         const territoryId = RefApplicationParameterHook(con.SELECTED_TERRITORY_ID)

                  
        const data = AgnosticDataLoader(con.DATASET_LENGUAS_AGE_DISTRIBUTION_AGNOSTIC);

        
        // sort data
        data.sort(function(a, b) {
            return -(b["edad_inicio"] - a["edad_inicio"]);
        }); 
 
         // Initial Parameters
         const  parameters = useMemo(() => {
            let title = "Distribución de lenguas por edad";
            if (includeTerritoryInTitle) {
                if (communityId !== con.NO_COMMUNITY_SELECTED) {
                    title = `Distribución de lenguas por edad en la comunidad ${getCommunityByID(communityId)[con.COMMUNITY_NAME]} (${getTerritoryByID(territoryId)[con.TERRITORY_NAME]})`
                } else if (territoryId !== con.NO_TERRITORY_SELECTED){
                    title = `Distribución de lenguas por edad en ${getTerritoryByID(territoryId)[con.TERRITORY_NAME]}`
                }
            }
            
            return({ 
             x : d => d.grupo_etario,
             y : d => d.total,
             title : title,
             group1 : 'total_tradicional',
             group2 : 'total_occidental',
             total : 'total',
             variable : 'grupo_etario',
             geoIdentifier : 'territorio',
             xLabel : "Grupo etario",
             yLabel : "Personas",
             colorGroup1 : "var(--lenguas-tradicionales-color)", // Bars Color 
             colorGroup2 : "var(--lenguas-occidentales-color)",
             marginBottom : 140,
             sorted : false
         })}, [communityId, territoryId, includeTerritoryInTitle])

 
         useEffect(() => {

            if(data.length > 0) 
                HorizonatlBarChart.build(objectReference, data, parameters, displayMode)
             
            
         }, [data, communityId, parameters, displayMode])
 
         
 
         return (
             <div width='100%'>
                 {data.length > 0 && <svg ref={objectReference} />}
             </div>
         );
 
 }


 LenguasDistributionChart.defaultProps = {
    includeCommunityInTitle : false
}
  
  export default LenguasDistributionChart