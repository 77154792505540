import React, { useEffect, useMemo, useRef } from 'react'

import DivergentVerticalBarChart from '../../containers/d3Charts/DivergentVerticalBarChart'

import * as con from "../../Constants"
import { RefApplicationParameterHook } from '../hooks/ApplicationParamatersHook'
import { getTerritoryByID } from '../../utils/dataFunctions'
import TerritoryDataLoader from '../data/TerritoryDataLoader'

function SexBarChartByTerritory({includeTerritoryInTitle}) {



        // Object Reference
        const objectReference = useRef()


        // Selected Community and Territory
        const territoryId = RefApplicationParameterHook(con.SELECTED_TERRITORY_ID)
        const communityId = RefApplicationParameterHook(con.SELECTED_COMMUNITY_ID)

        // Display Mode
        const displayMode = RefApplicationParameterHook(con.DISPLAY_MODE)

        // Territory data
        const data = TerritoryDataLoader(con.DATASET_SEX_DISTRIBUTION)

        // Initial Parameters
        const  parameters  = useMemo(() => {return({ y: d => d.comunidad,
                                title : includeTerritoryInTitle ? `Número de Hombres y Mujeres en ${getTerritoryByID(territoryId)[con.TERRITORY_NAME]}`
                                        : "Número de Hombres y Mujeres",
                                xLeft: d => d.total_hombres,
                                xRight: d => d.total_mujeres,
                                leftColor : "var(--men-color)",
                                rightColor : "var(--women-color)",
                                xLeftLabel : "Hombres",
                                xRightLabel : "Mujeres",
                                yLabel : "Comunidad", 
                                yLabelGap : 35,                               
                                selectedElement : communityId === con.NO_COMMUNITY_SELECTED ? null : data.find((com) => com[con.COMMUNITY_ID] === communityId)
        })}, [includeTerritoryInTitle, territoryId, communityId, data])



        useEffect(() => {
            

            if(data.length > 0) 
                DivergentVerticalBarChart.build(objectReference, data, parameters, displayMode)

        }, [data, communityId, parameters, displayMode])

        

        return (
            <div style={{width : "100%"}}>
                {data.length > 0 && <svg ref={objectReference} />}
            </div>
        );

}

SexBarChartByTerritory.defaultProps = {
    includeTerritoryInTitle : false,
    highlightCommunity : false
}


export default SexBarChartByTerritory