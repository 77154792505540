import React, { useEffect, useMemo, useRef } from 'react'


import PieChart from '../../containers/d3Charts/PieChart'
import  {scaleLinear, interpolatePuBuGn, interpolateViridis} from "d3"

import * as con from "../../Constants"
import { RefApplicationParameterHook } from '../hooks/ApplicationParamatersHook'

import CommunityDataLoader from '../data/CommunityDataLoader'

function LanguagesPieChartByCommunity({includeTerritoryInTitle}) {



        // Object Reference
        const objectReference = useRef()
        
        // Display Mode
        const displayMode = RefApplicationParameterHook(con.DISPLAY_MODE)

        // Selected Community
        const communityId = RefApplicationParameterHook(con.SELECTED_COMMUNITY_ID)
        
       
        // Community data
        const dataCommunities = CommunityDataLoader(con.DATASET_LANGUAGES_COMMUNITY)

        // Initial Parameters
        // let nameExtractor = d => d.nombre_lengua
        // let valueExtractor = d => d => d.total
        // let sortingFunction = (a, b) => valueExtractor(a) - valueExtractor(b)
        const  parameters = useMemo(() => {return({ 
            nameExtractor  : d => d.nombre_lengua,
            valueExtractor : d => d.total,
            sorted : false,
            textRadius : 180,
            title : "Distribución de Lenguas",
            color : null
        })}, [])


        useEffect(() => {
    

            if(dataCommunities.length > 0) {

                // find index for Castellano or portugues
                let idxOccidental = [];
                for(let i = 0 ; i < dataCommunities.length; i++){
                    if(dataCommunities[i].tipo_lengua === "occidental") idxOccidental.push(i)
                }

                function colorPicker(i, idxs) {
                    let tradicionalColor = scaleLinear()
                        .domain([0,dataCommunities.length])
                        .range([interpolatePuBuGn(1), interpolateViridis(0.5)])
                    if(idxs.includes(i)) return 'var(--lenguas-occidentales-color)'
                    else return tradicionalColor(i)
                }

                parameters.color = d => colorPicker(d, idxOccidental)


                PieChart.build(objectReference, dataCommunities, parameters, displayMode)
            }
        }, [ dataCommunities, communityId, parameters, displayMode])

        

        return (
            <div>
                {dataCommunities.length > 0 && <svg ref={objectReference} />}
            </div>
        );

}

LanguagesPieChartByCommunity.defaultProps = {
    includeTerritoryInTitle : false
}


export default LanguagesPieChartByCommunity