// Types of action

// User Data 
export const SET_USER_PARAMETER = "SET_USER_PARAMETER"  
export const LOGIN = "LOGIN"

// Application Parameters
export const SET_APPLICATION_PARAMETER = "SET_APPLICATION_PARAMETER"  

// Application Data
export const SET_APPLICATION_DATA = "SET_APPLICATION_DATA"