import React, { useEffect, useMemo, useRef } from 'react'


import HorizonatlBarChart from '../../containers/d3Charts/HorizonatlBarChart'

import * as con from "../../Constants"
import { RefApplicationParameterHook } from '../hooks/ApplicationParamatersHook'

import CommunityDataLoader from '../data/CommunityDataLoader'
import { educationGroupSortingFunction, translateEducationGroup } from '../../utils/translateFunctions'


function EducationBarChartByCommunity({includeTerritoryInTitle}) {



        // Object Reference
        const objectReference = useRef()

        
        // Display Mode
        const displayMode = RefApplicationParameterHook(con.DISPLAY_MODE)

        // Selected Community
        const communityId = RefApplicationParameterHook(con.SELECTED_COMMUNITY_ID)

        // Initial Parameters
        const  parameters = useMemo(() => {return({ 
            x : d => translateEducationGroup(d.grupo_escolar),
            y : d => d.total,
            sortingFunction : (a,b) => educationGroupSortingFunction(a.grupo_escolar, b.grupo_escolar),
            group1 : 'total_hombres',
            group2 : 'total_mujeres',
            total : 'total',
            variable : 'grupo_escolar',
            geoIdentifier : 'comunidad',
            width : 1800, 
            height : 750, 
            xLabel : "Nivel",
            yLabel : "Personas",
        })}, [])
        
       
        // Territory data
        const dataCommunities = CommunityDataLoader(con.DATASET_EDUCATION_COMMUNITY)


        useEffect(() => {
            
            if(dataCommunities.length > 0) 
                HorizonatlBarChart.build(objectReference, dataCommunities, parameters, displayMode)

        }, [ dataCommunities, communityId, parameters, displayMode])

        

        return (
            <div>
                {dataCommunities.length > 0 && <svg ref={objectReference} />}
            </div>
        );

}

EducationBarChartByCommunity.defaultProps = {
    includeTerritoryInTitle : false
}


export default EducationBarChartByCommunity