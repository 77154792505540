import React, { useRef, useEffect, useState } from 'react';
import * as mapcon from './mapconstants';
import * as con from '../../Constants';


import './mapbox-gl.css';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
mapboxgl.accessToken = con.MAPBOX_TOKEN;

export default function BasicMap({
    layers=null,
    includeBar=true,
    selectors=null,
    mapBackground=null,
    width=mapcon.DEFAULT_WIDTH,
    height=mapcon.DEFAULT_HEIGHT}) {

    // Object Reference
    const mapContainer = useRef(null)
    const map = useRef(null)

    // Map style
    let mapStyle =  mapcon.MAP_STYLE;
    if(mapBackground) {
        mapStyle = mapBackground
    } 

    // app state
    const selectedFeatureName = "selectedFeature"

    for(let s of selectors) {
        let hookFunction = s[mapcon.SELECTOR_PARAMETER_HOOK]
        let selectedFeature = hookFunction(s[mapcon.SELECTOR_PARAMETER]);
        s[selectedFeatureName] = selectedFeature
    }

    

    const [lng, setLng] = useState(mapcon.INIT_LONG);
    const [lat, setLat] = useState(mapcon.INIT_LAT);
    const [zoom, setZoom] = useState(mapcon.INIT_ZOOM);
    const [mapLoaded, setMapLoaded] = useState(false);
    const [sourcesAdded, setSourcesAdded] = useState(false);
    const [selectorBinded, setSelectorBinded] = useState(false);

    useEffect(() => {
        // initialize map only once 
        if (!map.current) {
            map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: mapStyle,
            center: [lng, lat],
            zoom: zoom
            });
        }
    
        // interaction
        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });

        // check if map laoded
        const legend = document.getElementById('legend'); 
        map.current.on('load', () => { 
            setMapLoaded(true);  
            legend.style.display = 'block';
        });
        
    }, [lat, lng, zoom, mapStyle]);



    useEffect(() => {
        // Change cursor to indicate interaction\
        map.current.getCanvas().style.cursor = 'pointer';

        if(mapLoaded) {
            // Add sources to map
            if(layers) {
                layers.forEach((layer) => {
                    if (!map.current.getSource(layer[mapcon.LAYER_NAME])) {
                        map.current.addSource(layer[mapcon.LAYER_NAME], {
                            type: 'geojson',
                            data: layer[mapcon.LAYER_DATA],
                            promoteId: layer[mapcon.LAYER_FEATURE_IDENTIFIER]
                        });
                    }
                })
                setSourcesAdded(true);
            }

            // render layers
            if(layers) {
                layers.forEach((layer) => {
                    if(!map.current.getLayer(layer[mapcon.LAYER_NAME])) {
                        map.current.addLayer(layer[mapcon.LAYER_VIZ_PARAMS]);
                    }
                })
            }

            // Add selector functions.
            if(!selectorBinded) {
                for(let s of selectors) {
                    let layerId = s[mapcon.SELECTOR_LAYER_ID]
                    map.current.on('click', layerId, (e) => {

                        // bind selector function (pass state to parent)
                        const selectorFunction = s[mapcon.SELECTOR_FUNCTION]
                        let featureIdendifier = s[mapcon.LAYER_FEATURE_IDENTIFIER]
                        let featureId = e.features[0]['properties'][featureIdendifier];
                        selectorFunction(featureId);
                    });

                }
                
            setSelectorBinded(true);
            }
        }

    }, [layers, mapLoaded, selectorBinded, selectors])

    useEffect(() => {
        if (sourcesAdded && mapLoaded && selectorBinded) {
            // First remove state from all features
            for(let s of selectors) {
                let layerId = s[mapcon.SELECTOR_LAYER_ID]
                const features = map.current.querySourceFeatures(layerId);
                for(let f of features) {
                    let removeId = f.id
                    map.current.setFeatureState({
                        source: layerId,
                        id: removeId
                    },  {
                        clicked: false
                    });
                };  
            }
            // Next change selected features state 
            let featureId = null;
            for(let s of selectors) {
                let layerId = s[mapcon.SELECTOR_LAYER_ID]
                featureId = s[selectedFeatureName];
                map.current.setFeatureState({
                    source: layerId,
                    id: featureId,
                }, {
                    clicked: true
                });
                
            }
        }
    // Following line disables this warning:
    // "React Hook useEffect has a spread element in its dependency array. 
    // This means we can't statically verify whether you've passed the correct dependencies  react-hooks/exhaustive-deps"
    // eslint-disable-next-line
    }, [...selectors.map(s => s[selectedFeatureName]), mapLoaded, selectorBinded, selectors, sourcesAdded]);


    return (
        <div>
            {!sourcesAdded ? 
                <div className="loadingbar">
                    Loading map...
                </div> : <div></div>}
            <div>
                {includeBar ? 
                    <div className="sidebar">
                        Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
                    </div> : <div></div>}
                <div ref={mapContainer} className="map-container" style={{width: width, height: height}}>
                    <div className="map-legend">
                    {/* {
                        layers ? layers.map((l) => <div key={l.LAYER_NAME} className='map-legend-item'><span id={l.LAYER_NAME}></span>{l.LEGEND_NAME}</div>) : <div></div>
                    } */}
                    </div>
                </div>

            </div>
            <div>
                
            </div>
        </div>
    );
}