import './App.css';
import React, { useEffect, useRef } from 'react'
import { Link, Route, Routes} from "react-router-dom"

// General
import * as con from "./Constants"

// static
import lenguasDeforstacionImg from "./static/img/lenguas_deforestacion.png"
import areaCalculationImg from "./static/img/calculo_areas.png"
import descargaDatosImg from "./static/img/descarga_datos.png"

// Components

import { CommunitySelect, DahsboardDisplayModeSelect, TerritorySelect, DahsboardTypeSelect } from './components/parameters/applicationParameters';
import TerritoryGeneralView from './dashboards/TerritoryGeneralView';
import ReactToPrint from "react-to-print"
import CommunityGeneralView from './dashboards/CommunityGeneralView';
import { RefApplicationParameterHook } from './components/hooks/ApplicationParamatersHook';
import { Button, ProgressBar, Text } from '@blueprintjs/core';
import { loadAllDataIntoStore } from './database/dbManager';
import LenguasDeforestationView from './dashboards/LenguasDeforestationView';
import AreaCalculation from './dashboards/AreaCalculationView';
import DataDownload from './dashboards/DataDownloadView';
import { RefUserParameterHook } from './components/hooks/UserParamatersHook';
import LoginPage from './pages/LoginPage';

//<MainComponent ref={componentRef}/>
//<ReactToPrint trigger={() => <button>Imprimir</button>} content={() => componentRef.current}/>


function App() {

  // Logged in
  const isAuthenticated = RefUserParameterHook(con.IS_AUTHENTICATED)

  // Databases Loaded
  const databaseLoading = RefApplicationParameterHook(con.DATABASES_LOADED)

  // Online
  const isOnline = RefApplicationParameterHook(con.IS_ONLINE)

  // Loads Data
  useEffect(() => {

    if(isAuthenticated)
      loadAllDataIntoStore()
    
  }, [isAuthenticated])

    // Selected Dashboard type
    
  return (
    <div className="App">     
      { !isAuthenticated 
        ? <LoginPage />        
        : isOnline && databaseLoading[con.STATUS] !== con.OK 
        ? <div style={{marginTop : "200px", marginLeft : "25%", marginRight : "25%"}}>
            <h2>Cargando las bases de datos....</h2>
            <ProgressBar value={databaseLoading[con.PROGRESS]}/>

        </div>
        : <div style={{marginBottom : "50px"}}>
          <Routes>
            <Route path={con.PATH_HOME} extact={true} element={<Home />}/>   
            <Route path={con.PATH_INDICATORS} extact={true} element={<Indicators />} />
            <Route path={con.PATH_DEMOGRAPHICS} extact={true} element={<PrintableDemographics />} />       
            <Route path={con.PATH_LENGUAS_DASHBOARD} extact={true} element={<LenguasDeforestationView />} />
            <Route path={con.PATH_AREAS_DASHBOARD} extact={true} element={<AreaCalculation />} />
            <Route path={con.PATH_DATA_DOWNLOAD} extact={true} element={<DataDownload />} />
          </Routes>          
        </div>     
      } 
    </div>

      
  );
}

function Indicators() {
  return (
      <div className="row-container">
      <Link to={con.PATH_LENGUAS_DASHBOARD}>
          <img className='indicadorButton' src={lenguasDeforstacionImg} alt="imagen de deforestacion y lenguas"/>
      </Link>
      <Link to={con.PATH_AREAS_DASHBOARD}>
          <img className='indicadorButton' src={areaCalculationImg} alt="imagen de calculo de areas"/>
      </Link>
      <Link to={con.PATH_DATA_DOWNLOAD}>
          <img className='indicadorButton' src={descargaDatosImg} alt="imagen de descarga de datos"/>
      </Link>
    </div>
  );
}


function PrintableDemographics(ref) {

  const componentRef = useRef();

  return (
    <div>
      <div className="parameterBox">
        <TerritorySelect/>
        <DahsboardDisplayModeSelect />
        <CommunitySelect />       
        <div style={{padding : "10px"}}> 
          <ReactToPrint trigger={() => <Button>Imprimir</Button>} content={() => componentRef.current}/>
        </div>
      </div>
      <Demographics ref={componentRef}/>
    </div>
  );
}


const Demographics = React.forwardRef((props, ref) => {
  
  const communityId = RefApplicationParameterHook(con.SELECTED_COMMUNITY_ID)
  const territoryId = RefApplicationParameterHook(con.SELECTED_TERRITORY_ID)


  return (
    <div ref={ref} style={{marginBottom : "50px"}}>
        <div>
          <div className="columnParameterBox">
            {territoryId === con.NO_TERRITORY_SELECTED ? 
            <Text title="Favor Seleccione un Territorio">Vista General de Territorio</Text>:
              communityId === con.NO_COMMUNITY_SELECTED ? 
              <TerritoryGeneralView />:
              <CommunityGeneralView />
            }
          </div>
      </div>
      </div>
  );
});


const Home = () => {

  return(
    <div>
      <DahsboardTypeSelect />
    </div>
  )

}

export default App;




