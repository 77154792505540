
import * as dataFun from '../../utils/dataFunctions'
import * as con from '../../Constants'
import * as mapcon from '../../containers/mapbox/mapconstants'


export const TERRITORIOS_CONSTANTS = {
    [mapcon.LAYER_NAME] : "territorios",
    [mapcon.LEGEND_NAME] : "Territorios",
    [mapcon.LAYER_FEATURE_IDENTIFIER] : con.TERRITORY_ID,
    [mapcon.LAYER_COLOR] : "#b58200",
    [mapcon.LAYER_FEATURE_NAME] : con.TERRITORY_NAME,
    [mapcon.DB_INFO] : dataFun.getDBInfo(con.GEODATASET_TERRITORIES),
   
}

export const TE_AATI_CONSTANTS = {
    [mapcon.LAYER_NAME] : "te_aati",
    [mapcon.LEGEND_NAME] : "Asociaciones de Autoridades Tradicionales Indigenas",
    [mapcon.LAYER_FEATURE_IDENTIFIER] : con.AATI_ID,
    [mapcon.LAYER_COLOR] : "#90C8AC",
    [mapcon.LAYER_FEATURE_NAME] : "NOMBRE_AAT",
    [mapcon.DB_INFO] : dataFun.getDBInfo(con.GEODATASET_TE_AATI),
}

export const LAYER_CONSTANTS = {
    territorios : TERRITORIOS_CONSTANTS,
    te_aati : TE_AATI_CONSTANTS
}

export const INTERSECTION_COLOR = "#FC63B9"

export const INTERSECTION_VIZ_PARAMS = {
    'id': 'intersection',
    'type': 'fill',
    'source': 'intersection',
    'layout': {
        // Make the layer visible by default.
        'visibility': 'visible'
    },
    'paint': {
        'fill-color': [
            'case',
            ['boolean',['feature-state', 'clicked'], false],
            mapcon.FILL_COLOR, // if selected
            INTERSECTION_COLOR 
        ],
        'fill-opacity': 0.3,
        'fill-outline-color': "#000000"
    }
}