// Application Data Hooks
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import * as con from "../../Constants"

/**
 * Hook for a single application data set in the store read only. Can return Null 
 */
export function RefApplicationNullableDataHook(dataSetId) {

    const value = useSelector(state => state[con.STORE][con.REDUCER_APPLICATION_DATA][dataSetId])

    return(value);
}


/**
 * Hook for a single application array data set in the store read only. Will return an empty array if dataset is null 
 */
 export function RefApplicationArrayDataHook(dataSetId) {

    const value = useSelector(state => state[con.STORE][con.REDUCER_APPLICATION_DATA][dataSetId])

    const [notNullvalue, setNotNullValue] = useState(() => [])

    useEffect(() => {
      
        if(value === null || value === undefined)
            setNotNullValue([])
        else
            setNotNullValue(value)

    }, [value])
    

    return(notNullvalue);
}

/**
 * Hook for a single application object data set in the store read only. Will return an empty object if dataset is null 
 */
 export function RefApplicationObjectDataHook(dataSetId) {

    const value = useSelector(state => state[con.STORE][con.REDUCER_APPLICATION_DATA][dataSetId])

    const [notNullvalue, setNotNullValue] = useState(() => {})

    useEffect(() => {
      
        if(value === null || value === undefined)
            setNotNullValue({})
        else
            setNotNullValue(value)

    }, [value])
    

    return(notNullvalue);
}