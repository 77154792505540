import { useSelector } from 'react-redux';

import * as con from "../../Constants"
import { setUserParameter } from '../../store/actions/userParameters';

/**
 * Hook for a single user parameter in the store read only
 */
export function RefUserParameterHook(parameterName) {

    const value = useSelector(state => state[con.STORE][con.REDUCER_USER_PARAMETERS][parameterName])

    return(value);
}


/**
 * Hook for a single user parameter in the store read and write
 */
 export function StateUserParameterHook(parameterName) {

    const value = useSelector(state => state[con.STORE][con.REDUCER_USER_PARAMETERS][parameterName])

    const setParameter = (newVal) => setUserParameter(parameterName, newVal)

    return([value, setParameter]);
}