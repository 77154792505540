// Reducer for all the store

import * as types from "../actions/types"
import * as con from "../../Constants"
import { PEDAGOGIC } from "../../containers/d3Charts/chartConstants"
import { sum } from "../../utils/generalFunctions"


// Model Keys
const APPLICATION_PARAMETERS = con.REDUCER_APPLICATION_PARAMETERS
const APPLICATION_DATA = con.REDUCER_APPLICATION_DATA
const USER_PARAMETERS = con.REDUCER_USER_PARAMETERS

const initialState = {

    // User Data
    [USER_PARAMETERS] : {
        [con.IS_AUTHENTICATED] : con.CONFIG["is_authenticated"],
        [con.TOKEN] : null,
        [con.USERNAME] : null
    },
    // Application Parameters
    [APPLICATION_PARAMETERS] : {[con.IS_ONLINE] : con.CONFIG["online"],
                                [con.DISPLAY_MODE] : PEDAGOGIC,
                                [con.AVAILABLE_COMMUNITIES] : {},
                                [con.AVAILABLE_TERRITORIES] : {},
                                [con.SELECTED_TERRITORY_ID]: con.NO_TERRITORY_SELECTED,
                                [con.SELECTED_COMMUNITY_ID]: con.NO_COMMUNITY_SELECTED,
                                [con.SELECTED_LANGUAGE]: con.NO_LANGUAGE_SELECTED,
                                [con.SELECTED_DATASET]: con.NO_DATASET_SELECTED,
                                [con.RESIDENT_VALUE] : con.RESIDENTS_ALL,
                                [con.DASHBOARD_SIZE] : con.A4_SIZE,
                                [con.LOGGED_IN] : {[con.STATUS] : con.NOT_STARTED},
                                [con.DATABASES_LOADED] : {[con.STATUS] : con.NOT_STARTED,
                                                          [con.PROGRESS] : 0},
                                [con.DATABASE_STATUS] : Object.fromEntries(con.ALL_AVAILABLE_DATASETS.map(databaseId => [databaseId, { [con.STATUS]: con.NOT_STARTED}])),                                                          
                            },
    // Application Datasets
    [APPLICATION_DATA] : Object.fromEntries(con.ALL_AVAILABLE_DATASETS.map(databaseId => [databaseId, null])),
                                
}


export default function setStateFun(state = initialState, action)
{

    // Global variables

    let parameterId = null;
    let parameterValue = null;
    let currentParameters = null;
    let datasetId = null;
    let dataset = null;
    let currentProgress = null;
    let databaseStatus = null;
    
    switch(action.type){

        // User Parameters
        case types.SET_USER_PARAMETER:

            return({...state,
                [USER_PARAMETERS] : {...state[USER_PARAMETERS], [action.payload[con.ID]] : action.payload[con.VALUE]}}) 

        case types.LOGIN:

            return({...state,
                [USER_PARAMETERS] : {...state[USER_PARAMETERS], [con.TOKEN] : action.payload[con.TOKEN], [con.IS_AUTHENTICATED] : true}}) 
                        

        // Application Parameters
        case types.SET_APPLICATION_PARAMETER:

            

            parameterId = action.payload[con.ID]
            parameterValue = action.payload[con.VALUE]

            currentParameters = state[APPLICATION_PARAMETERS]
            currentParameters[parameterId] = parameterValue

            switch(parameterId)
            {
                case con.SELECTED_TERRITORY_ID:
                    currentParameters[con.SELECTED_COMMUNITY_ID] = con.NO_COMMUNITY_SELECTED
                    break
                default:
                    break

            }

            return({...state, 
                        [APPLICATION_PARAMETERS] : {...currentParameters}})
            
        // Application Data
        case  types.SET_APPLICATION_DATA:


            datasetId = action.payload[con.ID]
            dataset = action.payload[con.VALUE]

            currentParameters = state[APPLICATION_PARAMETERS]
            
            // Checks for special cases
            switch(datasetId)
            {
                case con.DATASET_AVAILABLE_TERRITORIES:
                    currentParameters[con.AVAILABLE_TERRITORIES] = Object.fromEntries(dataset.map(t => [t[con.TERRITORY_ID], t]))
                    currentParameters[con.SELECTED_TERRITORY_ID] = dataset[0][con.TERRITORY_ID]
                    currentParameters[con.SELECTED_COMMUNITY_ID] = con.NO_COMMUNITY_SELECTED
                    break
                case con.DATASET_AVAILABLE_COMMUNITIES:
                    currentParameters[con.AVAILABLE_COMMUNITIES] = Object.fromEntries(dataset.map(t => [t[con.COMMUNITY_ID], t]))
                    currentParameters[con.SELECTED_COMMUNITY_ID] = con.NO_COMMUNITY_SELECTED
                    break                    
                default:
                    break

            }

            // Progress         
            // Sets value   
            currentParameters[con.DATABASE_STATUS] = {...currentParameters[con.DATABASE_STATUS], [action.payload[con.ID]] : { [con.STATUS]: con.OK}}
            
            // Recomputes Progress and Assigns
            currentProgress = sum(Object.values(currentParameters[con.DATABASE_STATUS]).map(db => db[con.STATUS] === con.OK ? 1 : 0))/Object.keys(currentParameters[con.DATABASE_STATUS]).length
            currentParameters[con.DATABASES_LOADED] = {[con.STATUS] : currentProgress === 1 ? con.OK : con.LOADING, [con.PROGRESS] : currentProgress}

            
            return({...state,
                    [APPLICATION_PARAMETERS] : {...currentParameters}, 
                    [APPLICATION_DATA] : {...state[APPLICATION_DATA], [action.payload[con.ID]] : action.payload[con.VALUE]}})
        default:
            return(state)       

    }    



}
