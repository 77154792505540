import React, { useEffect, useMemo, useRef } from 'react'


import DivergentVerticalBarChart from '../../containers/d3Charts/DivergentVerticalBarChart'

import * as con from "../../Constants"
import { RefApplicationParameterHook } from '../hooks/ApplicationParamatersHook'
import { getTerritoryByID} from '../../utils/dataFunctions'


import * as dfd from "danfojs"
import { RIGHT } from '../../containers/d3Charts/chartConstants'
import TerritoryDataLoader from '../data/TerritoryDataLoader'


function AgeSexBarChartByTerritory({includeTerritoryInTitle}) {



        // Object Reference
        const objectReference = useRef()
        
        // Display Mode
        const displayMode = RefApplicationParameterHook(con.DISPLAY_MODE)

        // Selected  Territory
        const territoryId = RefApplicationParameterHook(con.SELECTED_TERRITORY_ID)

        // Initial Parameters
        const  parameters = useMemo(() => {return({ y: d => d[con.AGE_GROUP],
                                                    title : includeTerritoryInTitle ?`Edades de Hombres y Mujeres en ${getTerritoryByID(territoryId)[con.TERRITORY_NAME]}` :
                                                                "Edades de Hombres y Mujeres",
                                                    
                                                    sorted : false,
                                                    marginRight : 165,
                                                    marginLeft : 15,
                                                    xLeft: d => d[con.TOTAL_MEN],
                                                    xRight: d => d[con.TOTAL_WOMEN],
                                                    leftColor : "var(--men-color)",
                                                    rightColor : "var(--women-color)",
                                                    xLeftLabel : "Hombres",
                                                    xRightLabel : "Mujeres",
                                                    yLabel : "Grupo Etario",
                                                    yAxisPosition : RIGHT,
                                                    numRows : 3,
                            })}, [territoryId, includeTerritoryInTitle])
                            


       
        // Territory data
        const data = TerritoryDataLoader(con.DATASET_AGE_DISTRIBUTION)


        useEffect(() => {
            
            if(data.length > 0) 
                {                    

                    const cleanData = data.map(ob => {return({
                        [con.AGE_GROUP] : ob[con.AGE_GROUP],
                        [con.AGE_START] : ob[con.AGE_START],
                        [con.TOTAL_MEN] : parseInt(ob[con.TOTAL_MEN]),
                        [con.TOTAL_WOMEN] : parseInt(ob[con.TOTAL_WOMEN]),                       
                    })})

                    let df = new dfd.DataFrame(cleanData)                    
                    // Groups by
                    df = df.loc({columns : [con.AGE_GROUP, con.AGE_START, con.TOTAL_MEN, con.TOTAL_WOMEN]})
                    df = df.groupby([con.AGE_GROUP, con.AGE_START]).sum()
                    // Renames
                    df = df.rename( Object.assign({}, ...[con.TOTAL_MEN, con.TOTAL_WOMEN].map((col) => ({[`${col}_sum`]: col}))), {axis : 1});
                    // Converts to JSON
                    df = dfd.toJSON(df)                    
  
                    DivergentVerticalBarChart.build(objectReference, parseData(df) , parameters, displayMode)
   
                }

        }, [data, parameters, displayMode])

        
        

        return (
            <div style={{width : "100%"}}>
                {data.length > 0 && <svg ref={objectReference} />}
            </div>
        );

}


AgeSexBarChartByTerritory.defaultProps = {
    includeTerritoryInTitle : false
}

const parseData = (data) =>
{

    if(data.length === 0)
        return([])

    
    data.sort((a,b) => a[con.AGE_START] - b[con.AGE_START])
    

    return(data)


}

export default AgeSexBarChartByTerritory