import { arc as d3Arc, path, scaleLinear, select, pie} from "d3"

import * as charCon from "./chartConstants";

class PieChart{


    static build = (objectReference, data, 
                        {   valueExtractor = d => d.value, // given d in data, returns the (quantitative) y-value
                            nameExtractor = d => d.name,
                            sorted = true, // If the data should be sorted
                            sortingFunction =  (a, b) => valueExtractor(a) - valueExtractor(b),//Sorting Function
                            title, // Title of plot
                            titleSize = 26,
                            titleGap = 20, // Gap between top and title
                            width = 600, // the outer width of the chart, in pixels
                            height = 600, // the outer height of the chart, in pixels
                            radius = 140, // Pie chart radius
                            textRadius = 170, // Text Radius
                            textLineGap = 0.01, // Gap between pie slice and text line. Factor of radius
                            textLabelLineGap = 0.01, // Gap between the line and the label. Factor of radius   
                            color = scaleLinear().domain([0,data.length]).range(["gainsboro", "darkOrange"]),
                            computeStep = (i) => 1.6**(-1*i)                       
                        } = {}, displayMode = charCon.REGULAR) => {

        
        // Sorts Data
        if(sorted)
            data = data.sort(sortingFunction);

        // Creates the SVG
        const svgEl = select(objectReference.current)

        // Removes any children
        svgEl.selectAll("*").remove();

        const svg = svgEl.attr("viewBox", `0 0 ${width} ${height}` )
                        .attr('width', '100%')   

        const pieChartData = data.map(elem => {return({ [charCon.NAME] : nameExtractor(elem), [charCon.VALUE] : valueExtractor(elem) })});

        const midPoint = [width/2, height/2]
    
    
        // Builds Pie Data
        let pieData = pie()
            .value((d) => d.value)
            .sort(sortingFunction)(pieChartData);

        // Adds Quarter and Mid Angle
        let quarterCuts = [data.length, data.length,data.length,data.length]
        pieData = pieData.map((elem,i) => {
            
            const midAngle = (elem.endAngle - elem.startAngle)/2 + elem.startAngle;


            let quarter = null
            if(midAngle <= Math.PI/2)
                quarter = 1
            else if(midAngle > Math.PI/2 && midAngle <= Math.PI)
                quarter = 2
            else if(midAngle > Math.PI && midAngle <= 3*Math.PI/2)
                quarter = 3
            else
                quarter = 4

            quarterCuts[quarter-1] = Math.min(quarterCuts[quarter-1], i)
                        
            return({...elem, [charCon.QUARTER] : quarter, [charCon.MID_ANGLE] : midAngle})
        
        })

        
        // Arc
        const arc = d3Arc().innerRadius(0).outerRadius(radius);      
    
    
        // Pie Chart
        const arcGroup = svg
            .append("g")
            .attr("transform", `translate(${midPoint[0]}, ${midPoint[1]})`)
            .selectAll("path")
            .data(pieData);
    
        arcGroup
            .join("path")
            .attr("fill", (d, i) => color(i))
            .attr("d", arc);
            
        
        arcGroup
            .join("path")
            .style("stroke", "black")
            .style("fill", "none")
            //.attr("d", (d,i) => buildTextLine(d,i));
        
        
        // Quarter by Quarter
       let quarters = [1,2,3,4];

       quarters.forEach(q => {

                    
            let quarter = pieData.filter(elem => elem[charCon.QUARTER] === q)

            let angleScale = scaleLinear([0, quarter.length], [Math.PI/2, 0]);

            // If not too crowded, will place labels at mid angle
            if(quarter.length <= 5)
                angleScale = (i) => Math.PI/2 - (quarter[i][charCon.MID_ANGLE] - (q-1)*Math.PI/2)


            // Midline increase function
            let midlineLength = 1                        

            // Builds text and line
            quarter.forEach((elem, i) => {


                let angle = angleScale(i) - (q-1)*Math.PI/2            

                //Text
                let x = Math.cos(angle)*(textRadius);
                let y = -1*Math.sin(angle)*(textRadius);
                let rotate = -1*(angle)*180/Math.PI
            
                let anchor = "start"
                if(rotate >= 90)
                {
                    rotate -= 180
                    anchor = "end"

                }
                svg.append("g")
                    .attr("transform", `translate(${midPoint[0]}, ${midPoint[1]})`)
                    .append("text")
                    .attr("transform", `translate(${x},${y})rotate(${rotate})`)
                    .text(elem.data.name)
                    .attr('dominant-baseline','middle')
                    .attr('text-anchor', anchor)            
                    .attr("fill", "#333");

                //Line
                const maxMidLine = 3/5


                let sliceAngle = Math.PI/2 - elem[charCon.MID_ANGLE]

                let lineStartRadius = radius*(1 + textLineGap)
                let lineBendRadius1 = (1/5 + maxMidLine*midlineLength)*(textRadius - radius) + radius
                let lineBendRadius2 = (4/5)*(textRadius - radius) + radius                
                let linEndRadius = textRadius - radius*textLabelLineGap

                // Adjusts midline length
                if(q <= 2)
                    midlineLength = computeStep(i)
                else
                    midlineLength = computeStep(quarter.length - i)

                let context = path();
                
                
        
                // Computes coordinates
                let coordinates = [[Math.cos(sliceAngle)*(lineStartRadius), -1*Math.sin(sliceAngle)*(lineStartRadius)]] // Line start
                coordinates.push([Math.cos(sliceAngle)*(lineBendRadius1), -1*Math.sin(sliceAngle)*(lineBendRadius1)]) // Line Bend
                coordinates.push([Math.cos(angle)*(lineBendRadius2), -1*Math.sin(angle)*(lineBendRadius2)]) // Line Bend2
                coordinates.push([Math.cos(angle)*(linEndRadius),-1*Math.sin(angle)*(linEndRadius)]) // Line End

                // Makes Line
                //context.moveTo(0,0);
                context.moveTo(coordinates[0][0],coordinates[0][1]);
                context.lineTo(coordinates[1][0],coordinates[1][1]);
                context.lineTo(coordinates[2][0],coordinates[2][1]);
                context.lineTo(coordinates[3][0],coordinates[3][1]);

                // Adds to SVG
                svg.append("g")
                    .attr("transform", `translate(${midPoint[0]}, ${midPoint[1]})`)
                    .append("path")
                    .style("stroke", "black")
                    .style("fill", "none")
                    .attr("d", context);
                

            })

       })

    
       // Adds Title
       svg.append('text')
       .attr('x', midPoint[0])
       .attr('y', titleGap)
       .attr('text-anchor', 'middle')
       .style('font-size', titleSize+ "px")
       .text(title)

        

    }


    static buildEmpty= (objectReference, parameters) =>
    {
        //TODO
    }

}

export default PieChart