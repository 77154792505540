// Sigeti API data loader

import * as con from "../../Constants"
import axios from 'axios';

import selectedDataSets from "../selectedDataSets.json"
import { constructHeaders } from "../../utils/authorizationFunctions";

const DB_TO_FILE_MAP = selectedDataSets

// Main Class
 class SigetiApiDataLoader {

    // Checks if the loader can load a specific dataset
    static canLoadDataSet = (dataSetId) => {
        return(dataSetId in DB_TO_FILE_MAP)
    }   


    // Loads a single database into store
    static loadSingleDataSetIntoStore = async (dataSetId) =>
    {

        // Builds Header
        let headers = constructHeaders()

        let dbObject = DB_TO_FILE_MAP[dataSetId]
        let format = dbObject[con.FORMAT]
        let url;
        switch(format) {
            case con.JSON:
                url = `${con.BQ_API_FETCH_TABLE}${dbObject[con.DATABASE]}/${dbObject[con.TABLE]}`
                break;
            case con.GEOJSON:
                url = `${con.BQ_API_FETCH_GEOTABLE}${dbObject[con.DATABASE]}/${dbObject[con.TABLE]}`
                break;
            default:
                // defaults to JSON
                url = `${con.BQ_API_FETCH_TABLE}${dbObject[con.DATABASE]}/${dbObject[con.TABLE]}`
                break;
        }
        
        let res = await axios.get(url, {headers : headers});

        return(res.data[con.DATA])
            
    }
}

export default SigetiApiDataLoader