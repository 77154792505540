import React, { useEffect, useMemo, useRef } from 'react'


import HorizonatlBarChart from '../../containers/d3Charts/HorizonatlBarChart'

import * as con from "../../Constants"
import { RefApplicationParameterHook } from '../hooks/ApplicationParamatersHook'

import UnfilteredDataLoader from '../data/UnfilteredDataLoader';


function CommunityBarChartByLanguage({includeTerritoryInTitle}) {

        // Object Reference
        const objectReference = useRef()

        
        // Display Mode
        const displayMode = RefApplicationParameterHook(con.DISPLAY_MODE);

        // Selected Community
        const communityId = RefApplicationParameterHook(con.SELECTED_COMMUNITY_ID);

        // Selected Language
        const selectedLanguage = RefApplicationParameterHook(con.SELECTED_LANGUAGE);

        // Initial Parameters
        var  parameters = useMemo(() => {return({ 
            x : d => d.id_cnida,
            y : d => d.total,
            group1 : 'total_hombres',
            group2 : 'total_mujeres',
            total : 'total',
            variable : 'id_cnida',
            geoIdentifier : 'comunidad',
            xLabel : "Comunindad",
            yLabel : "Personas",
            title : `Distribución por comunidad`, 
            marginBottom : 160,
            yLabelGap : 75,
            translateXLabels : d => d.comunidad
        })}, [])
        
       
        // Language data
        const data = UnfilteredDataLoader(con.DATASET_LANGUAGES_COMMUNITY)
    
        useEffect(() => {

            // Filter based on selected language
            if(selectedLanguage !== con.NO_LANGUAGE_SELECTED) {

                parameters.title = `Distribución de hablantes de ${selectedLanguage} por comunidad y sexo`;
           
                var filteredData = data.filter(function (e) {
                    return e.nombre_lengua === selectedLanguage
                })
                
                if(filteredData.length > 0) 
                    HorizonatlBarChart.build(objectReference, filteredData, parameters, displayMode)
            }

        }, [communityId, parameters, displayMode, selectedLanguage, data])

        return (
            <div>
                {data.length > 0 && <svg ref={objectReference} />}
            </div>
        );

}

CommunityBarChartByLanguage.defaultProps = {
    includeTerritoryInTitle : false,
}


export default CommunityBarChartByLanguage