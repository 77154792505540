import { useEffect, useState } from 'react'
import * as con from "../../Constants"
import { RefApplicationArrayDataHook } from '../hooks/ApplicationDataHook'
import { RefApplicationParameterHook } from '../hooks/ApplicationParamatersHook'

function CommunityDataLoader(dataSetId) {
  
    // Selected Terriotry
    const selectedCommunityId = RefApplicationParameterHook(con.SELECTED_COMMUNITY_ID)

    // Selected DataSet
    const dataSet = RefApplicationArrayDataHook(dataSetId)
 
    // All and filtered data
    const [data, setData] = useState(() => [])

    // Updates the filter
    useEffect(() => {
                  
        if(dataSet.length > 0 && selectedCommunityId !== null)
            setData(dataSet.filter(ob => ob[con.COMMUNITY_ID] === selectedCommunityId))
    

    }, [dataSet, selectedCommunityId])
    
    
    return(data)

  
}

export default CommunityDataLoader