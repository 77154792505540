import React from 'react'
//import { Link } from "react-router-dom"


import * as con from "../Constants"
import SexBarChartByTerritory from '../components/charts/SexBarChartByTerritory';
import { RefApplicationParameterHook } from '../components/hooks/ApplicationParamatersHook';
import { getCommunityByID } from '../utils/dataFunctions';
import { TotalChagras, TotalElders, TotalEthnicities, TotalFamilies, TotalMalocas, TotalPeople } from '../components/summaries/GeneralStatistics';

//import logoSigeti from "../static/img/sigeti.png"
//import logoGaia from "../static/img/gaia.png"
import EthnicBarChartByCommunity from '../components/charts/EthnicBarChartByCommunity';
import AgeSexBarChartByCommunity from '../components/charts/AgeSexBarChartByCommunity';
import EducationPieChartByCommunity from '../components/charts/EducationPieChartByCommunity';
import EducationBarChartByCommunity from '../components/charts/EducationBarChartByCommunity';
import { Text } from '@blueprintjs/core';
import LanguagesBarChartByCommunity from '../components/charts/LanguagesBarChartByCommunity';

const STATISTICS_CHART = con.COMMUNITY

function CommunityGeneralView() {



    const communityId = RefApplicationParameterHook(con.SELECTED_COMMUNITY_ID)

  return (
    <div style={{padding : "0vh"}}>
        <div className="parameterBox">
          {/* <div style={{height : "65px", width:"120px"}}>
            <Link to={con.PATH_HOME}>
              <img src={logoSigeti} height="100%" alt="logo sigeti"/>
            </Link>
          </div>             */}
            <h1 style={{color : "var(--title-color)", marginBottom : "0.5vh", marginTop : "0.5vh"}}>{"Comunidad " + getCommunityByID(communityId)[con.COMMUNITY_NAME] + " (Censo 632)"}</h1>            
            {/* <div style={{height : "60px", width:"120px"}}>
              <img src={logoGaia} height="65px" alt="logo gaia"/>
            </div> */}
        </div>
        <div style={{height : "0.2vh", backgroundColor : "green", opacity:0.7}}></div>
        <div className="parameterBoxCenterd" style={{marginBottom : "1vh", marginTop : "0.5vh"}}>
            <TotalFamilies type={STATISTICS_CHART}/> 
            <TotalEthnicities type={STATISTICS_CHART}/>
            <TotalElders type={STATISTICS_CHART}/>
            <TotalPeople type={STATISTICS_CHART}/>     
            <TotalMalocas type={STATISTICS_CHART}/>            
            <TotalChagras type={STATISTICS_CHART}/>     
        </div>
        <div className="parameterBox">     
            <SexBarChartByTerritory highlightCommunity={true}/>        
           <AgeSexBarChartByCommunity />
        </div>
        
      <div style={{marginTop : "2vh"}}>
        <EthnicBarChartByCommunity />
      </div>

      <div style={{marginTop : "2vh"}}>
        <LanguagesBarChartByCommunity />
      </div>

      <Text style={{fontSize : "24px", marginTop : "5vh"}}>Educación</Text>
      <div className="parameterBox" >
        <div style={{width : "33%"}}>
          <EducationPieChartByCommunity />
        </div>
        <div style={{width : "67%"}}>
          <EducationBarChartByCommunity />
        </div>
      </div>
    </div>
  )
}

export default CommunityGeneralView