
function LegendItem({color, name, size}) {

    return (
        <div className="row-container" style={{
            alignItems: "flex-start", 
            margin: "5px 5px 0px 5px",
            alignSelf: "baseline"
            }}>
        <div style={{
            border: `${size}px solid ${color}`,
            borderRadius: '100px',
            marginRight: '2px',
        }}>   
        </div>
        <p>{name}</p>
        </div>
    )
}

export default LegendItem