import {map, scaleBand, scaleLinear, axisLeft, select, axisBottom, max} from "d3"
import * as charCon from "./chartConstants";


class HorizonatlBarChart{


    static build = (objectReference, data, 
                            {
                                x = d => d, // given d in data, returns the (quantitative) y-value
                                y = d => d, // total
                                group1 = 'group1',
                                group2 = 'group2',
                                total = 'total',
                                variable = 'variable',
                                geoIdentifier = 'territorio',
                                sorted = true, // If the data should be sorted
                                sortingFunction =  (a, b) => b[total] - a[total],//Sorting Function
                                order = [],
                                title, // Title of plot
                                titleSize = 30,
                                titleGap = 25, // Gap between top and title
                                marginTop = 100, // the top margin, in pixels
                                marginRight = 15, // the right margin, in pixels
                                marginBottom = 120, // the bottom margin, in pixels
                                marginLeft = 100, // the left margin, in pixels
                                width = 1800, // the outer width of the chart, in pixels
                                height = 510, // the outer height of the chart, in pixels
                                lowerGap = 15, // Gap between the last line and the x axis
                                colorGroup1 = "var(--men-color)", // Bars Color 
                                colorGroup2 = "var(--women-color)",
                                colorNeutral = "grey",
                                axisTextSize = 18,
                                barsNumberSize = 21,               
                                labelSize = 21,
                                xLabelGap = 5, // Distance from the lower part of the graph to the x axis label 
                                yLabelGap = 35, // Distance from the left part of the graph to the y axis label 
                                xLabel = "Left", // Left x label
                                yLabel = "YLabel", // Y label
                                translateXLabels = false,
                                translateYLabels = false,

                            } = {}, displayMode = charCon.REGULAR) => {


        // Fills Nans and correct type
        data = data.map(function(d)  {
            if(d[variable] === null) {
                return {
                    [geoIdentifier]: d[geoIdentifier],
                    [variable]: "NS/NR",
                    [total]: d[total] ? parseInt(d[total]) : d[total],
                    [group1]: d[group1] ? parseInt(d[group1]) : d[group1],
                    [group2]: d[group2] ? parseInt(d[group2]) : d[group2]
                }
            }
            
            return  {
                [geoIdentifier]: d[geoIdentifier],
                [variable]: d[variable],
                [total]: d[total] ? parseInt(d[total]) : d[total],
                [group1]: d[group1] ? parseInt(d[group1]) : d[group1],
                [group2]: d[group2] ? parseInt(d[group2]) : d[group2]
            }
        })

        if(sorted) {
            // sort data
            data.sort(sortingFunction);  

        } else if(order.length > 0) {
            let orderedData = order.map((i) => {
                return data.filter(obj => i === obj[variable])
            });
            data = orderedData;
        }

        let xLabels = map(data, x);
        let yValues = map(data, y);
        let xLabelsTranslated;

        if(translateXLabels) {
            xLabelsTranslated = map(data, translateXLabels);
        }

        const xScale = scaleBand()
            .domain(xLabels)
            .rangeRound([marginLeft, width - marginRight])
            .padding(0.1); // padding around bands

        let bandWidth = xScale.bandwidth();
        let maxY = max(yValues)
        
        // if bandwith too small scale and rotate bar totals
        if(bandWidth < 30){
            barsNumberSize = barsNumberSize * 0.45
            axisTextSize = axisTextSize * 0.7
        }

        let maxbarHeight = (height - marginBottom - marginTop)
        let circleBox = Math.sqrt((bandWidth * maxbarHeight) / maxY);
        let numCols = Math.max(1,Math.floor(bandWidth / circleBox))

        // Declares the Y-Scale for regular mode
        let baseLine = height - marginBottom;
        let yScale = scaleLinear([0, maxY], [baseLine, marginTop]);
        let yAxis = axisLeft(yScale).tickSizeOuter(0);

        // Readjusts to deal with the rounding above
        // Gonche
        circleBox = maxbarHeight / Math.ceil(maxY / numCols)
        
        // scales return functions that will allow d3 to translate positions and sizes.
        let circleDiameter = (4 * circleBox) / 5 // 4/5 of the circle box
        let circleRadius = circleDiameter / 2;
        let padding = circleBox / 5 // 1/5 of the circle box


        // Creates the SVG
        const svgEl = select(objectReference.current)

        // Removes any children
        svgEl.selectAll("*").remove();

        const barChart = svgEl.attr("viewBox", `0 0 ${width} ${height}` )
                        .attr('width', '100%')   

        barChart.classed('barChart', true)



        // Adds Title
        barChart.append('text')
            .attr('x', width/2)
            .attr('y', titleGap)
            .attr('text-anchor', 'middle')
            .style('font-size', titleSize + "px")
            .text(title)

        // Axis Labels
        // X 
        barChart.append('text')
                .attr('x', width/2)
                .attr('y',height - xLabelGap)
                .attr('text-anchor', 'middle')
                .style('font-size', labelSize +  "px")
                .text(xLabel)

        
        barChart.append('text')
                .attr("transform", `translate(${yLabelGap}, ${(height - marginTop - marginBottom - lowerGap)/2 + marginTop}) rotate(270)`)
                .attr('text-anchor', 'end')
                .style('font-size', labelSize + "px")
                .text(yLabel)

        // Adds Y Axis if display mode is regular
        if(displayMode === charCon.REGULAR)
        {
            const yGroup = barChart.append("g")
                    .attr("transform", `translate(${marginLeft},0)`)
                    .call(yAxis)
                    .style("font-size",axisTextSize+"px");

            yGroup.call(g => g.selectAll(".tick line").clone()
                    .attr("x1", 0)
                    .attr("x2", width - marginLeft - marginRight)
                    .attr("stroke-opacity", 0.2))    
        }            
        
        const buildCircles = function(data) {
            var color;
            let circleData = data.map((d, i) => {
                let cx = xScale(x(d)) + (bandWidth / 2) - (numCols * circleBox / 2) + (circleBox / 2)// center bars
                let circles = [...Array(d[total]).keys()].map((cd, i) => {
                    if (i < d[group1]) {
                        color = colorGroup1
                    } else if (i < (d[group1] + d[group2])) {
                        color = colorGroup2
                    } else {
                        color = colorNeutral
                    }
                    return ({
                        id: "b" + i + "c" + cd,
                        cy: (height - marginBottom) - 2*circleRadius*Math.floor(cd / numCols) - Math.floor(cd / numCols) * padding - (circleBox)/2, //yScale(cd)
                        cx: cx + ((circleBox) * (cd % numCols)),
                        color: color
                    })
                }
                )
                return circles;
            })
            return circleData
        }  


        if(displayMode === charCon.PEDAGOGIC)
        {
            barChart.selectAll('.bar')
            .data(buildCircles(data))
            .enter()
            .append('svg') // append a rect for each datapoint
            .classed('bar', true)
                .append('g')
                .attr('id', (d, i) => "bar_" + i) // add indexes to modify bars individaully
                .selectAll('circle')
                .data(d => d)   // do one lever deeper
                .enter()
                    .append('circle')
                    .attr('class', 'circle')
                    .attr('fill', (cd) => cd.color)
                    .attr("r", circleRadius) // radius must be calculated
                    .attr("id", (cd) => cd.id)
                    .attr('cy', (cd) => cd.cy)
                    .attr('cx', cd => cd.cx)
        }
        else if(displayMode === charCon.REGULAR)
        {
            // Group1
            barChart.selectAll('.bar1')
                    .data(data)
                    .enter()
                    .append('rect') // append a rect for group1
                    .attr("x", d => xScale(d[variable]))
                    .attr("y", d=> yScale(d[group1]) )
                    .attr("width", bandWidth)
                    .attr("height",d=> baseLine - yScale(d[group1]))
                    .style("fill", colorGroup1)
            
            // Group 2
            barChart.selectAll('.bar2')
                    .data(data)
                    .enter()                    
                    .append('rect') // append a rect for group2
                    .attr("x", d => xScale(d[variable]))
                    .attr("y", d=> yScale(d[group2]) - (baseLine - yScale(d[group1])))
                    .attr("width", bandWidth)
                    .attr("height",d=> baseLine- yScale(d[group2]))
                    .style("fill", colorGroup2)                        

        }
        else
            console.error("No support for display mode: " + displayMode)


        
        // Add text
        barChart.selectAll('.labelText')
                .data(data)
                .enter().append('text') // append a rect for each datapoint
                    .attr('text-anchor', 'middle')
                    .style('font-size', barsNumberSize+"px")
                    .attr('x', d => xScale(x(d)) + bandWidth/2)
                    .attr('y', d => (height - marginBottom) - (Math.ceil(y(d) / numCols) * circleBox) - barsNumberSize/2)
                    .text(d => y(d));

        const xAxis = axisBottom(xScale);
        if(translateXLabels) {
            xAxis.tickFormat((d, i) => xLabelsTranslated[i]);
        }
       
        
        barChart.append("g")
            .attr('class', 'label')
            .attr("transform", "translate(0, " + (height - marginBottom) + ")")
            .call(xAxis)
            .selectAll("text")  
            .style("text-anchor", "end")
            .attr("dx", "-.8em")
            .attr("dy", "0.5em")
            .attr("transform", "rotate(-45)")
            .style("font-size",axisTextSize+"px");
            


        return barChart.node()
                                
        }

        static buildEmpty = (objectReference, parameters) =>
        {
            //TODO
        }
    

}

export default HorizonatlBarChart