// Actions for the model parameters reducer
import * as types from "./types"
import store from '../store';
import * as con from "../../Constants"
import { setApplicationParameter } from "./applicationParameters";
import axios from "axios";




// Set User Parameter 
export const setUserParameter = (id, value) =>
{    

    let payload = {[con.ID]: id, [con.VALUE] : value}

    store.dispatch({
        type : types.SET_USER_PARAMETER,
        payload : payload
    })
}

// Get User parameter
export const getUserParameter = (id) => {

    let parameter = store.getState()[con.STORE][con.REDUCER_USER_PARAMETERS][id]
    return(parameter)
}

// User Login
export const userLogin = (username, password) =>
{    

    // Sets Logging in to true
    setApplicationParameter(con.LOGGED_IN, {[con.STATUS] : con.LOADING})

    // Declares the headers
    const config = {
        "Content-Type":"application/json"
    }

    axios.post(con.LOGIN_API, {username, password}, config)
            .then(res => {
                
                store.dispatch({
                    type : types.LOGIN,
                    payload : res.data
                })

                setApplicationParameter(con.LOGGED_IN, {[con.STATUS] : con.OK})
                
            })
            .catch((error) => {
                                
                    // Check for credentials Error
                    if(error.response && error.response.data.non_field_errors && error.response.data.non_field_errors[0] === con.WRONG_CREDENTIALS)
                        setApplicationParameter(con.LOGGED_IN, {[con.STATUS] : con.WRONG_CREDENTIALS})
                    else
                    {                        
                        setApplicationParameter(con.LOGGED_IN, {[con.STATUS] : con.ERROR})
                    }
                })

}

