import {betweenness, degree, eccentricity, closeness} from 'ngraph.centrality'
import * as createGraph from "ngraph.graph"

export function getCentrality(edges, measure, directed=false, sourceName='source', targetName='target') {
    //var c = require('ngraph.centrality');
    //var g = require('ngraph.graph')();
    let g = createGraph()
    edges.forEach(e => g.addLink(e[sourceName], e[targetName]))
    let centrality;
    switch(measure) {
        case 'betweenness': 
            centrality = betweenness(g, directed);
            break;
        case 'degree': 
            centrality = degree(g, directed);
            break;
        case 'eccentricity': 
            centrality = eccentricity(g);
            break;
        case 'closeness': 
            centrality = closeness(g);
            break;
        default:
            centrality = degree(g, directed);
            break;
    }
    
    return centrality
}