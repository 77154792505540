import { RefApplicationArrayDataHook } from '../hooks/ApplicationDataHook';

function UnfilteredDataLoader(dataSetId) {

    // Selected DataSet
    const dataSet = RefApplicationArrayDataHook(dataSetId)

    // Selected DataSet   
    return dataSet

}

export default UnfilteredDataLoader

