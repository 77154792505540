import {
  map, 
  forceManyBody, 
  drag as d3Drag, 
  forceLink as d3ForceLink, 
  forceCenter, 
  forceSimulation, 
  forceCollide, 
  sort,  
  scaleOrdinal, 
  scaleLinear, select} from "d3"


import { getCentrality } from '../../utils/networkFunctions'
import * as charCon from './chartConstants'

class NetworkChart{ 

    // Data structure
    // {
    //     nodes: [{id, group}, ..., {}],
    //     edges: [{source, target, value}, ..., {}]
    // }
    static build = (objectReference, 
                    nodes, links, 
                    onClick = d => d, // WHen clicked apply following function on the selected id
                    {
                    nodeFill = '#dc6626',
                    nodeStroke = '#000000',
                    nodeId = d => d.id, // given d in nodes, returns a unique identifier (string)
                    nodeGroup = d => d.group, // given d in nodes, returns an (ordinal) value for color
                    nodeGroups = [0, 1], // an array of ordinal values representing the node groups
                    nodeTitle, // given d in nodes, a title string
                    nodeStrokeWidth = 0.5, // node stroke width, in pixels
                    nodeStrokeOpacity = 1, // node stroke opacity
                    nodeRadius = 5, // node radius, in pixels
                    nodeStrength = -25,
                    linkSource = ({source}) => source, // given d in links, returns a node identifier string
                    linkTarget = ({target}) => target, // given d in links, returns a node identifier string
                    linkStroke = "#999", // link stroke color
                    linkStrokeOpacity = 0.6, // link stroke opacity
                    linkStrokeWidth = 1.5, // given d in links, returns a stroke width in pixels
                    linkStrokeLinecap = "round", // link stroke linecap
                    linkStrength = 0.08,
                    groupColors = [], // an array of color strings, for the node groups
                    width = 640, // outer width, in pixels
                    height = 400, // outer height, in pixels
                    invalidation, // when this promise resolves, stop the simulation
                    
                } = {}, displayMode = charCon.REGULAR) => {

        // Compute values.
        const N = map(nodes, nodeId).map(intern);
        const LS = map(links, linkSource).map(intern);
        const LT = map(links, linkTarget).map(intern);

        // Create a scale for size based on centrality
        const centrality = getCentrality(links, 'betweenness')
        let maxCentrality = Math.max(...Object.values(centrality))
        const sizeScale = scaleLinear()
          .domain([0, maxCentrality])
          .range([nodeRadius, 15]);

        if (nodeTitle === undefined) nodeTitle = (_, i) => N[i];

        const T = nodeTitle == null ? null : map(nodes, nodeTitle);
        const G = nodeGroup == null ? null : map(nodes, nodeGroup).map(intern);
        const W = typeof linkStrokeWidth !== "function" ? null : map(links, linkStrokeWidth);
        const L = typeof linkStroke !== "function" ? null : map(links, linkStroke);

        // Replace the input nodes and links with mutable objects for the simulation.
        nodes = map(nodes, (_, i) => ({id: N[i]}));
        links = map(links, (_, i) => ({source: LS[i], target: LT[i]}));

        // Compute default domains.
        if (G && nodeGroups === undefined) nodeGroups = sort(G);

        // Construct the scales.
        let color = nodeGroup == null ? null : scaleOrdinal(nodeGroups, groupColors);

        // Construct the forces.
        const forceNode = forceManyBody();
        const forceLink = d3ForceLink(links).id(({index: i}) => N[i]);
        if (nodeStrength !== undefined) forceNode.strength(nodeStrength);
        if (linkStrength !== undefined) forceLink.strength(linkStrength);

        const simulation = forceSimulation(nodes)
            .force("link", forceLink)
            .force("charge", forceNode)
            .force("center",  forceCenter())
            .force('collision', forceCollide().radius(function(d) {
              let size = Math.round(sizeScale(centrality[d.id]) * 100) / 100
              if(size) {
                return size
              }
              return nodeRadius
            }))
            .on("tick", ticked);


        let svg = select(objectReference.current)

        // Clean svg
        svg.selectAll("*").remove()
        
        svg.attr("width", width)
            .attr("height", height)
            .attr("viewBox", [-width / 2, -height / 2, width, height])
            .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      
        const link = svg.append("g")
          .attr("stroke", typeof linkStroke !== "function" ? linkStroke : null)
          .attr("stroke-opacity", linkStrokeOpacity)
          .attr("stroke-width", typeof linkStrokeWidth !== "function" ? linkStrokeWidth : null)
          .attr("stroke-linecap", linkStrokeLinecap)
        .selectAll("line")
        .data(links)
        .join("line");

        const node = svg.append("g")
            .attr("fill", nodeFill)
            .attr("stroke", nodeStroke)
            .attr("stroke-opacity", nodeStrokeOpacity)
            .attr("stroke-width", nodeStrokeWidth)
          .selectAll("circle")
          .data(nodes)
          .join("circle")
            .attr("r", (d) => {
              let size = Math.round(sizeScale(centrality[d.id]) * 100) / 100
              if(size) {
                return size
              }
              return nodeRadius
            })
            .attr("id", d => d.id)
            .call(drag(simulation))
            .on("mouseover", function(i, n) {
              let nId = select(this)
              
              // make mouse a pointer
              nId.style("cursor", "pointer")
              let id = nId.attr("id");
              let xPos = parseInt(nId.attr("cx")) + nodeRadius
              let yPos = parseInt(nId.attr("cy"))
              svg.append("text")
                .attr("x", xPos)
                .attr("y", yPos)
                .attr("dy", ".35em")
                .text(id)
            })
            .on('mouseout', function (d, i) {
              svg.selectAll("text").remove();
              // return mouse to default
              select(this).style("cursor", "default"); 
            })
            .on('click', click);
      
        if (W) link.attr("stroke-width", ({index: i}) => W[i]);
        if (L) link.attr("stroke", ({index: i}) => L[i]);
        if (G) node.attr("fill", ({index: i}) => color(G[i]));
        if (T) node.append("title").text(({index: i}) => T[i]);
        if (invalidation != null) invalidation.then(() => simulation.stop());

        function click() {
          let nId = select(this)
          let id = nId.attr("id");
          onClick(id)
        }
 
        function intern(value) {
          return value !== null && typeof value === "object" ? value.valueOf() : value;
        }
      
        function ticked() {

          let maxRight = width /  2 - 2*nodeRadius;
          let maxTop = height / 2 - 2*nodeRadius;

          node
            .attr("cx", d => d.x < -maxRight ? -maxRight : d.x  > maxRight ? maxRight : d.x)
            .attr("cy", d => d.y < -maxTop ? -maxTop : d.y > maxTop ? maxTop : d.y);

          link
            .attr("x1", d => d.source.x)
            .attr("y1", d => d.source.y)
            .attr("x2", d => d.target.x)
            .attr("y2", d => d.target.y);
        }

      
        function drag(simulation) {    
          function dragstarted(event) {
            if (!event.active) simulation.alphaTarget(0.5).restart();
            event.subject.fx = event.subject.x;
            event.subject.fy = event.subject.y;
          }
          
          function dragged(event) {
            event.subject.fx = event.x;
            event.subject.fy = event.y;
          }
          
          function dragended(event) {
            if (!event.active) simulation.alphaTarget(0);
            event.subject.fx = null;
            event.subject.fy = null;
          }
          
          return d3Drag()
            .on("start", dragstarted)
            .on("drag", dragged)
            .on("end", dragended);
        }
    }



}

export default NetworkChart