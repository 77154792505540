// Authorization Functions

import { TOKEN } from "../Constants"
import { getUserParameter } from "../store/actions/userParameters"

export const constructHeaders = () =>
{
    const token = getUserParameter(TOKEN)

    if(token === null)
        console.warn("Token is null, communication with API will probably result in 403 error.")

    let headers = {"Authorization" : `Token ${token}`}

    return(headers)
        
}