import {map, scaleBand, scaleLinear, axisLeft, axisRight, select, axisBottom, InternSet, max} from "d3"
import * as charCon from "./chartConstants";

class DivergentVerticalBarChart{


    static build = (objectReference, data, 
                        {   y = d => d, // given d in data, returns the (quantitative) y-value
                            xLeft = d => d,
                            xRight = d => d,
                            sorted = true, // If the data should be sorted
                            title, // Title of plot
                            titleSize = 26,
                            titleGap = 20, // Gap between top and title
                            marginTop = 40, // the top margin, in pixels
                            marginRight = 15, // the right margin, in pixels
                            marginBottom = 45, // the bottom margin, in pixels
                            marginLeft = 200, // the left margin, in pixels
                            width = 800, // the outer width of the chart, in pixels
                            height = 500, // the outer height of the chart, in pixels
                            barsMargin = 0.2, // Margin at the end of the bars (left and right)
                            //numRows = 3, // Number of rows per line
                            lowerGap = 15, // Gap between the last line and the x axis
                            numberMargin = 0.005, // Amount of width that separates bar and label
                            xRange = [marginLeft, width - marginRight], // [left, right]
                            yRange = [height - marginBottom, marginTop], // [bottom, top]
                            yAxisPosition  = charCon.LEFT,
                            xPadding = 0.002, // Amount of width that separates bars and mid point
                            leftColor = "#0096FF", // Left Bar color
                            rightColor = "#D22B2B", // Right bar color,
                            axisTextSize = 17,
                            barsNumberSize = 17,
                            labelSize = 21,
                            xLabelGap = 5, // Distance from the lower part of the graph to the x axis label 
                            yLabelGap = 28, // Distance from the left part of the graph to the y axis label 
                            xLeftLabel = "Left", // Left x label
                            xRightLabel = "Right", // Right x label
                            yLabel = "YLabel", // Y label
                            unselectedOpacity = 0.5, //Opacity of non selected elements
                            selectedElement, // If a single bar should be selected
                        } = {}, displayMode = charCon.REGULAR) => {




        let selectedBar = undefined
        if(selectedElement !== null && selectedElement !== undefined)
            selectedBar = y(selectedElement)
        
            
        //console.log(selectedElement)
        // console.log(data)
        // console.log(selectedBar)
        // console.log("------")

            

        // Sorts Data
        if(sorted)
            data = data.sort(function (a, b) {return (xLeft(b) + xRight(b)) - (xLeft(a) + xRight(a));});

        // Compute values.
        const Y = map(data, y);
        const XLeft = map(data, ob => -1*xLeft(ob));
        const XRight = map(data, ob => Math.abs(xRight(ob)));

        // Domains
        const yDomain = new InternSet(Y)
        const maxValue = Math.max(8,max(map(XLeft, i => Math.abs(i)).concat(XRight)))


        const xDomain = [-1*maxValue*(1+barsMargin), maxValue*(1+barsMargin)];

        // Construct scales, axes, and formats.
        const yScale = scaleBand(yDomain, yRange);
        const xScale = scaleLinear(xDomain, xRange);

        let yAxis = axisLeft(yScale).tickSizeOuter(0);
        if(yAxisPosition === charCon.RIGHT)
            yAxis = axisRight(yScale)
                            
        // X Axis
        const xAxis = axisBottom(xScale).ticks(Math.min(maxValue, 8)).tickFormat((d,i) => Math.abs(d));

        // Relevant Values
        const midPoint = xScale(0)
        const barHeight = yScale.bandwidth()*0.9;

        const maxBarWidth = (width - marginRight - marginLeft)/2
        let circleBox = Math.sqrt((barHeight * maxBarWidth) / maxValue);
        let numRows = Math.max(1,Math.floor(barHeight / circleBox))

        // Creates the SVG
        const svgEl = select(objectReference.current)

        // Removes any children
        svgEl.selectAll("*").remove();

        const svg = svgEl.attr("viewBox", `0 0 ${width} ${height}` )
                        .attr('width', '100%')   

                
        // Creates the axis if mode is regular
        if(displayMode === charCon.REGULAR)
        {
            const xGroup = svg.append("g")
                            .attr("transform", `translate(0,${height - marginBottom})`)
                            .call(xAxis)
                            .style("font-size","12px");

                xGroup.call(g => g.selectAll(".tick line").clone()
                            .attr("y1", -1*(height - marginTop - marginBottom))
                            .attr("y2", 0)
                            .attr("stroke-opacity", 0.2))
        }

        // Adds the axis labels
        // X Left
        svg.append('text')
            .attr('x', xScale(-1*maxValue/2))
            .attr('y',height - xLabelGap)
            .attr('text-anchor', 'middle')
            .style('font-size', labelSize + "px")
            .text(xLeftLabel)
        
            // X Right
        svg.append('text')
            .attr('x', xScale(maxValue/2))
            .attr('y',height - xLabelGap)
            .attr('text-anchor', 'middle')
            .style('font-size', labelSize + "px")
            .text(xRightLabel)

        // Y
        let yGroup;
        if(yAxisPosition === charCon.LEFT)
        {

            svg.append('text')
            .attr("transform", `translate(${yLabelGap},${(height - marginTop - marginBottom - lowerGap)/2 + marginTop}) rotate(270)`)
            .attr('text-anchor', 'middle')
            .style('font-size', labelSize)
            .text(yLabel)

            yGroup = svg.append("g")
                .attr("transform", `translate(${marginLeft},0)`)
                .call(yAxis)
                .style("font-size",axisTextSize+"px");

            yGroup.call(g => g.selectAll(".tick line").clone()
                .attr("x1", 0)
                .attr("x2", width - marginLeft - marginRight)
                .attr("stroke-opacity", 0.2))
        }
        else
        {
            svg.append('text')
                .attr("transform", `translate(${width - yLabelGap},${(height - marginTop - marginBottom - lowerGap)/2 + marginTop}) rotate(270)`)
                .attr('text-anchor', 'middle')
                .style('font-size', labelSize)
                .text(yLabel)

            yGroup = svg.append("g")
                .attr("transform", `translate(${width-marginRight},0)`)
                .call(yAxis)
                .style("font-size",axisTextSize+"px");;

            yGroup.call(g => g.selectAll(".tick line").clone()
                .attr("x1", 0)
                .attr("x2", -(width - marginRight - marginLeft))
                .attr("stroke-opacity", 0.2))

        }

        


        if(selectedBar !== undefined)
            yGroup.selectAll('text').style('font-weight', (d) => d === selectedBar ? 900 : 400)



        // Adds Title
        svg.append('text')
            .attr('x', midPoint)
            .attr('y', titleGap)
            .attr('text-anchor', 'middle')
            .style('font-size', titleSize+ "px")
            .text(title)

        let globElement = svg.append("g")

        // Iterates over the given data
        // Left
        XLeft.forEach((val,k) => {

            let opacity = unselectedOpacity;
            if(selectedBar === undefined || selectedBar === Y[k])
                opacity = 1

            // Builds the bars
            if(displayMode === charCon.REGULAR)
            {
                buildRegularRectangle({elem : globElement, // elem 
                                        classId : `.Left${k}`, // Class Id 
                                        value : Math.abs(val), // Value 
                                        y : yScale(Y[k]), // y 
                                        barWidth : midPoint - xScale(XLeft[k]), // Width
                                        color : leftColor,
                                        opacity : opacity,
                                        orient : "LEFT"})

            }
            else if(displayMode === charCon.PEDAGOGIC)
            {
                buildRectangleFromCircles({elem : globElement, // elem 
                                        classId : `.Left${k}`, // Class Id 
                                        value : Math.abs(val), // Value 
                                        y : yScale(Y[k]), // y 
                                        barWidth : midPoint - xScale(XLeft[k]), // Width
                                        color : leftColor,
                                        opacity : opacity,
                                        orient : "LEFT"}
                                        )
            }
            else
                console.error("No support for display mode: " + displayMode)
            


            addText({ elem : globElement,
                        uniqueId : `TextLeft${k}`,
                        val : Math.abs(val),
                        x : xScale(XLeft[k]),
                        y : yScale(Y[k]),
                        opacity : opacity,
                        orient : "LEFT"
                        })

        })

        // Right
        XRight.forEach((val,k) => {

            let opacity = unselectedOpacity;
            if(selectedBar === undefined || selectedBar === Y[k])
            opacity = 1

            if(displayMode === charCon.REGULAR)
            {
                buildRegularRectangle({elem : globElement, // elem 
                                            classId : `.Right${k}`, // Class Id 
                                            value : Math.abs(val), // Value 
                                            y : yScale(Y[k]), // y 
                                            barWidth : xScale(XRight[k]) - midPoint, // Width
                                            color : rightColor,
                                            opacity : opacity,
                                            orient : "RIGHT"}
                                            )
            }
            else
            {
                buildRectangleFromCircles({elem : globElement, // elem 
                                            classId : `.Right${k}`, // Class Id 
                                            value : Math.abs(val), // Value 
                                            y : yScale(Y[k]), // y 
                                            barWidth : xScale(XRight[k]) - midPoint, // Width
                                            color : rightColor,
                                            opacity : opacity,
                                            orient : "RIGHT"}
                )
            }
            addText({ elem : globElement,
                    uniqueId : `TextRight${k}`,
                    val : Math.abs(val),
                    x : xScale(XRight[k]),
                    y : yScale(Y[k]),
                    opacity : opacity,
                    orient : "RIGHT"
                    })


        })



        // Function that adds the text at the end of the bar
        function addText({elem, // DOM D3 element
                            uniqueId, // Unique id for adjusting position
                            val, // Number to display 
                            x, // X positioin
                            y, // Y Position
                            opacity = 1,
                            orient = "RIGHT"
                            })
                            {

            if(val !== 0)
            {
            
                let step = orient === "LEFT" ? -1:1
                let textElement = elem.append('text')
                .attr('id',uniqueId)
                .attr('x', x +step*numberMargin*width)
                .attr('y', y + barHeight/2)
                .attr('dominant-baseline','middle')
                .style('opacity', opacity)
                .style('font-size', barsNumberSize+"px")
                .text(val);

                if(orient === "LEFT")
                    textElement.attr('text-anchor', 'end')
                else
                    textElement.attr('text-anchor', 'start')

            }

        }


        // Function for populating with circles
        function buildRectangleFromCircles({elem, // Element
                                            classId, // Class id to identify the elements
                                            value, // Actual value representing the bars value
                                            y,
                                            barWidth, 
                                            color = "blue",
                                            opacity = 1,
                                            orient = "RIGHT"
                                            } = {})
        {

                // Creates values per column
                let rowValues = Array(numRows).fill(Math.floor(value/numRows))

                // Adds missing values
                let missing = value % numRows
                if(missing > 0)
                    Array(missing).fill(1).forEach((_, i) => rowValues[i]+= 1)

                const maxElementsToDisplay = max(rowValues)

                const height =  barHeight/numRows
                const step = barWidth/maxElementsToDisplay

                const radius = 0.9*Math.max(1,Math.min(height,step))/2

                // Iterate over the number of rows
                rowValues.forEach(( elementsToDisplay, k) => {

                

                    let allIcons = elem.append("g")
                                        .selectAll(classId)
                                        .data(Array(elementsToDisplay).fill(1))
                                        .enter()
                                        .append('circle')

                    let stepDirection = orient === "RIGHT" ? 1 : -1;
                    let yPosition = numRows === 1? y + barHeight/2 : y + height*k + radius;
                    allIcons.style('fill', color)
                            .style('opacity', opacity)
                            .attr("cx", (d,i) => (midPoint + stepDirection*width*xPadding) + stepDirection*(i*step + radius))
                            .attr("cy", yPosition )
                            .attr("r", radius)


        })

    }



    // Function for populating with rectangles
    function buildRegularRectangle({elem, // Element
                                        classId, // Class id to identify the elements
                                        value, // Actual value representing the bars value
                                        y,
                                        barWidth, 
                                        color = "blue",
                                        opacity = 1,
                                        orient = "RIGHT"
                                        } = {})
    {

            let finalX = orient === "RIGHT" ? midPoint + xPadding*width : midPoint - barWidth - xPadding*width;
            elem.append('rect')
                .attr("x", finalX)
                .attr("y", y)
                .attr("width", barWidth)
                .attr("height", barHeight)
                .style("fill", color)
                .style('opacity', opacity);
            

    }

    return svgEl.node()


    }


    static buildEmpty= (objectReference, parameters) =>
    {
        //TODO
    }

}

export default DivergentVerticalBarChart