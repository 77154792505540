import { useState, useEffect } from 'react';

import * as con from '../../Constants'
import { RefApplicationParameterHook } from '../hooks/ApplicationParamatersHook';
import UnfilteredDataLoader from './UnfilteredDataLoader';


function AgnosticDataLoader(dataSetId) {

    // Selected Terriotry
    const selectedCommunityId = RefApplicationParameterHook(con.SELECTED_COMMUNITY_ID)
    const selectedTerritoryId = RefApplicationParameterHook(con.SELECTED_TERRITORY_ID)

    // Selected DataSet
    const dataTerritory = UnfilteredDataLoader(dataSetId[con.TERRITORY])
    const dataCommunity = UnfilteredDataLoader(dataSetId[con.COMMUNITY])

    // All and filtered data
    const [data, setData] = useState(() => [])

    // Updates the filter
    useEffect(() => {
        if(dataCommunity.length > 0 && selectedCommunityId !== con.NO_COMMUNITY_SELECTED) {
            setData(dataCommunity.filter(ob => ob[con.COMMUNITY_ID] === selectedCommunityId))}
        else if (dataTerritory.length > 0 && selectedTerritoryId !== con.NO_TERRITORY_SELECTED) {
            setData(dataTerritory.filter(ob => ob[con.TERRITORY_ID] === selectedTerritoryId))   } 

    }, [selectedTerritoryId, selectedCommunityId, dataTerritory, dataCommunity])
    

    // Selected DataSet   
    return(data)
  
}

export default AgnosticDataLoader

