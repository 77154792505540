import React, { useEffect, useMemo, useRef } from 'react'


import HorizonatlBarChart from '../../containers/d3Charts/HorizonatlBarChart'

import * as con from "../../Constants"
import { RefApplicationParameterHook } from '../hooks/ApplicationParamatersHook'

import CommunityDataLoader from '../data/CommunityDataLoader'


function EthnicBarChartByCommunity({includeTerritoryInTitle}) {



        // Object Reference
        const objectReference = useRef()

        
        // Display Mode
        const displayMode = RefApplicationParameterHook(con.DISPLAY_MODE)

        // Selected Community
        const communityId = RefApplicationParameterHook(con.SELECTED_COMMUNITY_ID)

        // Initial Parameters
        const  parameters = useMemo(() => {return({ 
            x : d => d.pueblo,
            y : d => d.total,
            group1 : 'total_hombres',
            group2 : 'total_mujeres',
            total : 'total',
            variable : 'pueblo',
            geoIdentifier : 'comunidad',
            xLabel : "Pueblo",
            yLabel : "Personas",
            title : "Distribución de Pueblos"
        })}, [])
        
       
        // Territory data
        const dataCommunities = CommunityDataLoader(con.DATASET_ETHNICITY_DATA_COMMUNITY)


        useEffect(() => {
            
            if(dataCommunities.length > 0) 
                HorizonatlBarChart.build(objectReference, dataCommunities, parameters, displayMode)

        }, [ dataCommunities, communityId, parameters, displayMode])

        

        return (
            <div>
                {dataCommunities.length > 0 && <svg ref={objectReference} />}
            </div>
        );

}

EthnicBarChartByCommunity.defaultProps = {
    includeTerritoryInTitle : false
}


export default EthnicBarChartByCommunity