// Constants
import config from "./config/config.json"


// Loads Config
export const CONFIG = config 

// API Urls
// -------------------
export const API_URL = config["api_url"]

// Mapbox Token
export const MAPBOX_TOKEN = config["mapbox-token"]

// BQ Api
export const BQ_API = API_URL +  "bq_api/"
export const BQ_API_FETCH_TABLE = BQ_API + "fetch_table/"
export const BQ_API_FETCH_GEOTABLE = BQ_API + "fetch_geotable/"
export const API_DATA_NAME = "data"
export const API_TABLE_NAME = "table"
export const API_DB_NAME = "db"
export const API_PROJECT_NAME = "project"
export const API_FORMAT_NAME = "format"
export const BQ_API_INTERSECT_FEATURES = BQ_API + "intersect_features/"

// User API
export const LOGIN_API = API_URL +  "users/login"

// Config Constants
export const SELECTED_TERRITORIES = "selected_territories"

// General Constants
export const ID = "id"
export const NAME = "name"
export const VALUE = "value"
export const DATA = "data"
export const TERRITORY = "territory"
export const COMMUNITY = "community"
export const DATABASE = "database"
export const TABLE = "table"
export const FORMAT = "format"
export const GEOJSON = "GEOJSON"
export const JSON = "JSON"

// Loading constants
export const STATUS = "status"
export const LOADING = "loading"
export const NOT_STARTED = "not_started"
export const OK = "ok"
export const ERROR = "error"
export const PROGRESS = "progress" 
export const DATABASE_STATUS = "database_status"




// Data Visualization Constants
export const TERRITORY_ID = "id_ti"
export const TERRITORY_NAME = "territorio"
export const COMMUNITY_ID = "id_cnida"
export const COMMUNITY_NAME = "comunidad"
export const AGE_GROUP = "grupo_etario"
export const TOTAL_MEN = "total_hombres"
export const TOTAL_WOMEN = "total_mujeres"
export const TOTAL = "total"
export const AGE_START = "edad_inicio"
export const AGE_END = "edad_fin"
export const TOTAL_COMMUNITIES = "numero_comunidades"
export const TOTAL_FAMILIES = "numero_familias"
export const TOTAL_CLANS = "numero_clanes"
export const TOTAL_ETHNICITIES = "numero_pueblos"
export const TOTAL_PEOPLE = "numero_personas"
export const TOTAL_PEOPLE_RESIDENT = "numero_personas_residentes"
export const TOTAL_PEOPLE_NOT_RESIDENT = "numero_personas_no_residentes"
export const TOTAL_ELDERS = "numero_sabedores"
export const TOTAL_CHAGRAS = "numero_chagras"
export const TOTAL_CHAGRAS_FAMILY = "numero_chagras_familiares"
export const TOTAL_CHAGRAS_COMMUNITY = "numero_chagras_comunidad"
export const TOTAL_MALOCAS = "numero_malocas"
export const AATI_ID = "ID_AATI"

// Constants associated with datasets:
// dataset_lenguas_edges, dataset_lenguas_nodes and dataset_histograma_lenguas
export const LENGUAS_NODE_ID = "nombre_lengua"
export const LENGUAS_NODE_GROUP = "tipo_lengua"
export const LENGUA_1 = "lengua_1"
export const LENGUA_2 = "lengua_2"
export const LENGUA_TOT = "total"
export const LENGUA_TRADICIONAL = 'tradicional'
export const LENGUA_OCCIDENTAL = 'occidental'

// Navegation Constants
export const PATH_HOME = "/"
export const PATH_GENRAL_TERRITORY_DASHBOARD = PATH_HOME + "dashboards/vista_general_territorio"
export const PATH_GENRAL_COMMUNITY_DASHBOARD = PATH_HOME + "dashboards/vista_general_comunidad"
export const PATH_INDICATORS = PATH_HOME + "dashboards/indicadores"
export const PATH_DEMOGRAPHICS = PATH_HOME + "dashboards/demografia"
export const PATH_LENGUAS_DASHBOARD = PATH_HOME + "dashboards/indicador_lenguas_deforestacion"
export const PATH_AREAS_DASHBOARD = PATH_HOME + "dashboards/calculo_areas"
export const PATH_DATA_DOWNLOAD = PATH_HOME + "dashboards/descarga_datos"

// Reducers
export const STORE = "store"
export const REDUCER_APPLICATION_PARAMETERS = "APPLICATION_PARAMETERS"
export const REDUCER_APPLICATION_DATA = "APPLICATION_DATA"
export const REDUCER_USER_PARAMETERS = "REDUCER_USER_PARAMETERS"

// User Data
export const IS_AUTHENTICATED = "is_authenticated"
export const USERNAME = "username"
export const TOKEN = "token"
export const WRONG_CREDENTIALS = "Wrong credentials"

// Application Parameters
export const IS_ONLINE = "is_online"
export const DISPLAY_MODE = "display_mode"
export const AVAILABLE_TERRITORIES = "AVAILABLE_TERRITORIES"
export const AVAILABLE_COMMUNITIES = "AVAILABLE_COMMUNITIES" 
export const SELECTED_TERRITORY_ID = "SELECTED_TERRITORY_ID"
export const SELECTED_COMMUNITY_ID = "SELECTED_COMMUNITY_ID"
export const SELECTED_LANGUAGE = "SELECTED_LANGUAGE"
export const SELECTED_DATASET = "SELECTED_DATASET"
export const RESIDENT_VALUE = "RESIDENT_VALUE"
export const DASHBOARD_SIZE = "DASHBOARD_SIZE"
export const A2_SIZE = "A2_SIZE"
export const A4_SIZE = "A4_SIZE"
export const DASHBOARD_TYPE = "DASHBOARD_TYPE"
export const INDICATORS = 'INDICATORS'
export const DEMOGRAPHICS = 'DEMOGRAPHICS'
export const LOGGED_IN = "logged_in"
export const DATABASES_LOADED = "databases_loaded"

export const SIZE_OPTIONS = [A2_SIZE, A4_SIZE] 


// Resident Values
export const RESIDENTS_ALL = "residents_all"
export const RESIDENTS_ONLY = "residents_only"
export const RESIDENTS_NOT = "residents_not"

export const RESIDENT_VALUE_OPTIONS = [RESIDENTS_ALL, RESIDENTS_ONLY, RESIDENTS_NOT]

// Territorry Ids
export const NO_TERRITORY_SELECTED = "__NO_TERRITORY_SELECTED__"
export const NO_TERRITORY_SELECTED_OBJECT = {[TERRITORY_ID] : NO_TERRITORY_SELECTED, [TERRITORY_NAME] : "--------------"}

// Community Ids
export const NO_COMMUNITY_SELECTED = "__NO_COMMUNITY_SELECTED__"
export const NO_COMMUNITY_SELECTED_OBJECT = {[COMMUNITY_ID] : NO_COMMUNITY_SELECTED, [COMMUNITY_NAME] : "TODAS"}

// Language 
export const NO_LANGUAGE_SELECTED = "__NO_LANGUAGE_SELECTED__"

// Dataset 
export const NO_DATASET_SELECTED = "__NO_DATASET_SELECTED__"

// Application Data Constants
// Available datasets
export const DATASET_AVAILABLE_TERRITORIES = "dataset_available_territories"
export const DATASET_AVAILABLE_COMMUNITIES = "dataset_available_communities"
export const DATASET_GENERAL_DATA_COMMUNITY = "dataset_general_data_community"
export const DATASET_GENERAL_DATA_TERRITORY = "dataset_general_data_territory"
export const DATASET_ETHNICITY_DATA_COMMUNITY = "dataset_ethnicity_data_community"
export const DATASET_ETHNICITY_DATA_TERRITORY = "dataset_ethnicity_data_territory"
export const DATASET_SEX_DISTRIBUTION = "dataset_sex_distribution"
export const DATASET_AGE_DISTRIBUTION = "dataset_age_distribution"
export const DATASET_EDUCATION_COMMUNITY = "dataset_education_community"
export const DATASET_EDUCATION_TERRITORY = "dataset_education_territory"
export const DATASET_LANGUAGES_TERRITORY = "dataset_language_territory"
export const DATASET_LANGUAGES_COMMUNITY = "dataset_language_community"
export const DATASET_LENGUAS_EDGES = "dataset_lenguas_edges"
export const DATASET_LENGUAS_NODES = "dataset_lenguas_nodes"
export const DATASET_LENGUAS_AGE_HISTOGRAM = "dataset_histograma_lenguas"
export const DATASET_LENGUAS_AGE_DISTRIBUTION_COMMUNITY = "distribucion_lenguas_por_edad_comunidad"
export const DATASET_LENGUAS_AGE_DISTRIBUTION_TERRITORY = "distribucion_lenguas_por_edad_territorio"
export const GEODATASET_TERRITORIES = "geography_territories"
export const GEODATASET_COMMUNITIES = "geography_communities"
export const DATASET_DEFORESTATION_COMMUNITY = "deforestacion_por_comunidad"
export const GEODATASET_TE_AATI = "te_aati"
export const GEODATASET_TE_ANP = "te_anp"

// Agnostic datasets
export const DATASET_LENGUAS_AGE_DISTRIBUTION_AGNOSTIC = {
    [TERRITORY] : DATASET_LENGUAS_AGE_DISTRIBUTION_TERRITORY,
    [COMMUNITY] : DATASET_LENGUAS_AGE_DISTRIBUTION_COMMUNITY
}

// All Available datasets
// Application will try to load each one using the database manager 
export const ALL_AVAILABLE_DATASETS = [
    DATASET_AVAILABLE_TERRITORIES,
    DATASET_AVAILABLE_COMMUNITIES,
    DATASET_GENERAL_DATA_COMMUNITY,      
    DATASET_GENERAL_DATA_TERRITORY,     
    DATASET_ETHNICITY_DATA_COMMUNITY, 
    DATASET_ETHNICITY_DATA_TERRITORY, 
    DATASET_SEX_DISTRIBUTION,
    DATASET_AGE_DISTRIBUTION,
    DATASET_EDUCATION_COMMUNITY,
    DATASET_EDUCATION_TERRITORY,
    DATASET_LANGUAGES_TERRITORY,
    DATASET_LANGUAGES_COMMUNITY,
    DATASET_LENGUAS_NODES,
    DATASET_LENGUAS_EDGES,
    DATASET_LENGUAS_AGE_DISTRIBUTION_COMMUNITY,
    DATASET_LENGUAS_AGE_DISTRIBUTION_TERRITORY,
    DATASET_LENGUAS_AGE_HISTOGRAM,
    GEODATASET_TERRITORIES,
    GEODATASET_COMMUNITIES,
    DATASET_DEFORESTATION_COMMUNITY,
    GEODATASET_TE_AATI,
]

// Map constants
export const LATITUDE = 'lat'
export const LONGITUDE = 'lon'
export const ZOOM = 'zoom'


// Education Groups
export const NO_SCHOOL = "Sin Escolaridad"
export const KINDERGARTEN = "Preescolar"
export const PRIMARY_SCHOOL = "Primaria"
export const MIDDLE_SCHOOL = "Media"
export const HIGH_SCHOOL = "Secundaria"
export const HIGHER_EDUCATION = "Universitario/Técnico"
export const HIGHER_EDUCATION_SHORT = "Univ. o Técnico"
