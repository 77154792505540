import React, { useEffect, useMemo, useRef } from 'react'


import PieChart from '../../containers/d3Charts/PieChart'

import * as con from "../../Constants"
import { RefApplicationParameterHook } from '../hooks/ApplicationParamatersHook'

import  {schemeCategory10, scaleOrdinal, scaleLinear, interpolatePuBuGn, interpolateViridis} from "d3"

import TerritoryDataLoader from '../data/TerritoryDataLoader'


function LanguagesPieChartByTerritory({includeTerritoryInTitle}) {



        // Object Reference
        const objectReference = useRef()
        
        // Display Mode
        const displayMode = RefApplicationParameterHook(con.DISPLAY_MODE)

        // Selected Community
        const territoryId = RefApplicationParameterHook(con.SELECTED_TERRITORY_ID)

        // Initial Parameters
        const  parameters = useMemo(() => {return({ 
            nameExtractor  : d => d.nombre_lengua,
            valueExtractor : d => d.total,
            sorted : false,
            textRadius : 180,
            title : "Distribución de Lenguas",
            color : scaleOrdinal(schemeCategory10)

        })}, [])
        
       
        // Community data
        const dataCommunities = TerritoryDataLoader(con.DATASET_LANGUAGES_TERRITORY)


        useEffect(() => {
            
            if(dataCommunities.length > 0) {
                // find index for Castellano or portugues
                let idxOccidental = [];
                for(let i = 0 ; i < dataCommunities.length; i++){
                    if(dataCommunities[i].tipo_lengua === "occidental") idxOccidental.push(i)
                }

                function colorPicker(i, idxs) {
                    let tradicionalColor = scaleLinear()
                        .domain([0,dataCommunities.length])
                        .range([interpolatePuBuGn(1), interpolateViridis(0.5)])
                    if(idxs.includes(i)) return 'var(--lenguas-occidentales-color)'
                    else return tradicionalColor(i)
                }

                parameters.color = d => colorPicker(d, idxOccidental)

                PieChart.build(objectReference, dataCommunities, parameters, displayMode)
            }
        }, [ dataCommunities, territoryId, parameters, displayMode])

        

        return (
            <div>
                {dataCommunities.length > 0 && <svg ref={objectReference} />}
            </div>
        );

}

LanguagesPieChartByTerritory.defaultProps = {
    includeTerritoryInTitle : false
}


export default LanguagesPieChartByTerritory