import { Button } from '@blueprintjs/core'
import React from 'react'

function LockEyePasswordButton({showPassword, setShowPassword}) {
  return (
    <Button          
            icon={showPassword ? "eye-off" : "eye-open"}                
            minimal={true}
            color={"black"}
            onClick={() => setShowPassword(!showPassword)}
        /> 
  )
}

export default LockEyePasswordButton