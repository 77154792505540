import { FormGroup, InputGroup, Text } from '@blueprintjs/core'
import React, { useEffect, useState } from 'react'
import { RefApplicationParameterHook } from '../components/hooks/ApplicationParamatersHook'
import { RefUserParameterHook } from '../components/hooks/UserParamatersHook'
import * as con from "../Constants"
import LoadingIcon from '../containers/loading/LoadingIcon'
import LockEyePasswordButton from '../containers/passwords/LockEyePasswordButton'
import { userLogin } from '../store/actions/userParameters'

function LoginPage() {

    // User Logged In
    const isAuthenticated = RefUserParameterHook(con.IS_AUTHENTICATED)


    // Application Parameters
    const logged = RefApplicationParameterHook(con.LOGGED_IN)

    // Credentials
    const [username, setUsername] = useState(() => "")
    const [password, setPassword] = useState(() => "")

    // State for pwd and login entered
    const [isFormFilled, setIsFormFilled] = useState(() => false)
    
    useEffect(() => {
      
        setIsFormFilled(username !== null && username !== "" && password !== null && password !== "")
      
    }, [username, password])
    

    const login = () =>
    {
        userLogin(username, password)
    }

    // To show/hide password
    const [showPassword, setShowPassword] = useState(() => false)


    const handleKeyPress = (event) => {
        if(isFormFilled && event.key === 'Enter'){
            login()
        }
      }

    return(
            <div style={{width : "350px", marginLeft : "40%", marginRight : "40%", marginTop : "25px"}} onKeyPress={handleKeyPress}>
                <div className={logged[con.STATUS] === con.LOADING ? "disabled-element" : ""}>
                    <div className="loginBox primary-background">                
                        <FormGroup label="Usuario" className={"inputUser"}>
                            <InputGroup id="text-input"                
                                value={username} 
                                    onChange={(e) => {setUsername(e.target.value)}} />
                        </FormGroup> 
                        
                        <FormGroup label="Contraseña" className={"inputPwd"}>
                            <InputGroup id="password"  
                            className="noCarret" 
                                type={ showPassword ? "text" :"password"}             
                                value={password} 
                                rightElement={<LockEyePasswordButton showPassword={showPassword} setShowPassword={setShowPassword} />}
                                onChange={(e) => {setPassword(e.target.value)}} />
                        </FormGroup>

                        <button onClick={login} disabled={!isFormFilled || logged[con.STATUS] === con.LOADING}>
                            Enviar    
                        </button>
                    </div>
                </div>
                   
                     <div style={{marginTop : "20px"}}>
                        {
                            logged[con.STATUS] === con.LOADING ? 
                            <div>
                                <div className="stackedBox-next">
                                    <div className="loginBox primary-background" style={{opacity : 0.85}}>            
                                    </div> 
                                </div>
                                <div className="stackedBox-next">
                                    <div className="loginBox">            
                                        <LoadingIcon />
                                    </div> 
                                </div>
                            </div>
                            : logged[con.STATUS] === con.WRONG_CREDENTIALS ? 
                            <Text> Usuario o Contraseña equivocados.</Text>
                            : logged[con.STATUS] === con.ERROR ? 
                            <Text> Hubo un error</Text>
                            : <div></div>
                        }
                </div>                
            </div>
                 
            

    )

}

export default LoginPage