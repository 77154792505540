import * as con from "./../../Constants"
import * as chartCon from "./../../containers/d3Charts/chartConstants"
import { RefApplicationParameterHook, StateApplicationParameterHook } from "../hooks/ApplicationParamatersHook"
import { MultipleChoiceElement } from "../../containers/FormElements"

import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { translateChartConstant, translateResidentValue, translateSizeValue } from "../../utils/translateFunctions"
import { RefApplicationArrayDataHook } from "../hooks/ApplicationDataHook"

// static
import demografiaImg from "../../static/img/demografia.png"
import exploradorImg from "../../static/img/explorador_datos.png"

export function TerritorySelect(parameters) {

  // Available Territories
  const territories = RefApplicationArrayDataHook(con.DATASET_AVAILABLE_TERRITORIES)
  
  // Selected Territory
  const [selectedTerritoryId, setSelectedTerritoryId] = StateApplicationParameterHook(con.SELECTED_TERRITORY_ID)

  // Local territories
  const [localTerritories, setLocalTerritories] = useState(() => [con.NO_TERRITORY_SELECTED_OBJECT])

  useEffect(() => {
    
    if(territories.length > 0)
      setLocalTerritories(territories)

  }, [territories])
  

  return (
      
    <MultipleChoiceElement name="Terrirorio" 
                            value={selectedTerritoryId} 
                            setValue={setSelectedTerritoryId}
                            values={localTerritories.map(t => t[con.TERRITORY_ID])}
                            valueLabels={localTerritories.map(t => t[con.TERRITORY_NAME])}
                            {...parameters}/>
  )
}


export function CommunitySelect({parameters}) {

  // Available Communities
  const communities = RefApplicationArrayDataHook(con.DATASET_AVAILABLE_COMMUNITIES)

  // Current Territory
  const territoryId = RefApplicationParameterHook(con.SELECTED_TERRITORY_ID)

  // Selected Community
  const [selectedCommunityId, setSelectedCommunityId] = StateApplicationParameterHook(con.SELECTED_COMMUNITY_ID)

  // Available communities
  const [availableCommunities, setAvailableCommunities] = useState(() => [con.NO_COMMUNITY_SELECTED_OBJECT].concat(communities.filter(com => com[con.TERRITORY_ID] === territoryId)))


  useEffect(() => {    

      const newCommunities = [con.NO_COMMUNITY_SELECTED_OBJECT].concat(communities.filter(com => com[con.TERRITORY_ID] === territoryId))
      setAvailableCommunities(newCommunities)
      

  }, [territoryId, communities])
  

  return (
      
    <MultipleChoiceElement name="Comunidad" 
                            value={selectedCommunityId} 
                            setValue={setSelectedCommunityId}
                            values={availableCommunities.map(t => t[con.COMMUNITY_ID])}
                            valueLabels={availableCommunities.map(t => t[con.COMMUNITY_NAME])}
                            {...parameters}/>
  )
}



export function ResidentSelect({parameters}) {

  // Current Residents Value
  const [residentValue, setResidentValue] = StateApplicationParameterHook(con.RESIDENT_VALUE)
  

  return (
      
    <MultipleChoiceElement name="Residentes" 
                            value={residentValue} 
                            setValue={setResidentValue}
                            values={con.RESIDENT_VALUE_OPTIONS}
                            valueLabels={con.RESIDENT_VALUE_OPTIONS.map(translateResidentValue)}
                            {...parameters}/>
  )
}



export function DashboardSizeSelect({parameters}) {

  // Current Dashboard Size
  const [dashboardSize, setDashboardSize] = StateApplicationParameterHook(con.DASHBOARD_SIZE)
  

  return (
      
    <MultipleChoiceElement name="Tamaño" 
                            value={dashboardSize} 
                            setValue={setDashboardSize}
                            values={con.SIZE_OPTIONS}
                            valueLabels={con.SIZE_OPTIONS.map(translateSizeValue)}
                            {...parameters}/>
  )
}


export function DahsboardDisplayModeSelect({parameters}) {

  // Current Dashboard Size
  const [displayMode, setDispplayMode] = StateApplicationParameterHook(con.DISPLAY_MODE)
  

  return (
      
    <MultipleChoiceElement name="Modo" 
                            value={displayMode} 
                            setValue={setDispplayMode}
                            values={chartCon.CHART_TYPES}
                            valueLabels={chartCon.CHART_TYPES.map(translateChartConstant)}
                            {...parameters}/>
  )
}

export function DahsboardTypeSelect({parameters}) {

  // Current Dashboard Type
  const [dashboardType, setDashboardType] = StateApplicationParameterHook(null)
  
  const changeType = (type) => {
    switch(type){
      case dashboardType: 
        break;
      case con.DEMOGRAPHICS:
        setDashboardType(type)
        break;
      case con.INDICATORS:
        setDashboardType(type)
        break;
      default:
        setDashboardType(con.DEMOGRAPHICS)
        break;
    }
  }

  return (
  <div>
    <Link to={con.PATH_DEMOGRAPHICS}>
      <img className='indicadorButton' src={demografiaImg} alt="imagen demografia" onClick={() => changeType(con.DEMOGRAPHICS)}/>
    </Link>    
    <Link to={con.PATH_INDICATORS}>
      <img className='indicadorButton' src={exploradorImg} alt="imagen explorador de datos" onClick={() => changeType(con.INDICATORS)} />
         
    </Link>
    </div>
  )
}

export function DatasetSelect(parameters) {

  // Available datasets
  const datasets = con.ALL_AVAILABLE_DATASETS

  // Current Dataset
  const [selectedDataset, setSelectedDataset] = StateApplicationParameterHook(con.SELECTED_DATASET)

  // Local territories
  const [localDatasets, setLocalDatasets] = useState(() => [con.NO_DATASET_SELECTED])

  useEffect(() => {
    
    if(datasets.length > 0)
    setLocalDatasets(datasets)

  }, [datasets])

  return (
      
    <MultipleChoiceElement name="Datasets" 
                            value={selectedDataset} 
                            setValue={setSelectedDataset}
                            values={localDatasets.map(t => t)}
                            valueLabels={localDatasets.map(t => t)}
                            {...parameters}/>
  )
}