// Translation functions

import * as con from "./../Constants"
import * as chartCon from "./../containers/d3Charts/chartConstants"





function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

export const translateAgeGroup = (ageGroup) =>
{
    ageGroup = ageGroup.replace('hombres_','')
    ageGroup = ageGroup.replace('mujeres_','')
    ageGroup = ageGroup.replace('edad_','')

    ageGroup = ageGroup.replace(new RegExp(escapeRegExp('_'), 'g'), ' ')

    ageGroup = `${ageGroup} Años`

    return(ageGroup)

}

export const getStartOfAgeGroup = (ageGroup) => 
{
    return(parseInt(translateAgeGroup(ageGroup).slice(0, 2)))
}

const RES_DICT = {
    [con.RESIDENTS_ALL] : "Todos",
    [con.RESIDENTS_ONLY] : "Residentes",
    [con.RESIDENTS_NOT] : "No Residentes"

}
export const translateResidentValue = (resVal) => {

    return(RES_DICT[resVal])
}

const SIZE_DICT = {
    [con.A2_SIZE] : "Cartelera",
    [con.A4_SIZE] : "Escritorio",

}
export const translateSizeValue = (sizeVal) => {

    return(SIZE_DICT[sizeVal])
}


// Translate Chart types
const CHART_CONSTANTS_DICT = {
    [chartCon.PEDAGOGIC] : "Contable",
    [chartCon.REGULAR] : "Sólido"
}

export const translateChartConstant = (chartConstant) => {

    if(chartConstant in CHART_CONSTANTS_DICT)
        return(CHART_CONSTANTS_DICT[chartConstant])

    return(chartConstant)
}

export const translateEducationGroup = (group) =>
{
    if(group === con.HIGHER_EDUCATION)
        return(con.HIGHER_EDUCATION_SHORT)

    return(group)
}

export const educationGroupSortingFunction = (g1, g2) =>
{

    const arr = [con.NO_SCHOOL, con.KINDERGARTEN, con.PRIMARY_SCHOOL, con.MIDDLE_SCHOOL, con.HIGH_SCHOOL, con.HIGHER_EDUCATION]
    
    return(arr.indexOf(g1) - arr.indexOf(g2))


}
