import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';

import * as con from '../../Constants'
import * as mapcon from '../../containers/mapbox/mapconstants'
import * as layercon from './AreasMapConstants'
import { constructHeaders } from "../../utils/authorizationFunctions";

import * as turf from '@turf/turf'

import UnfilteredDataLoader from '../data/UnfilteredDataLoader';

import '../../containers/mapbox/mapbox-gl.css';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
mapboxgl.accessToken = con.MAPBOX_TOKEN;


export default function AreasMap() {

    // 
    const includeBar = true
    const mapBackground = null
    const width = 600
    const height = 600

    // Object Reference
    const mapContainer = useRef(null)
    const map = useRef(null)

    // Map style
    let mapStyle =  mapcon.MAP_STYLE;
    if(mapBackground) {
        mapStyle = mapBackground
    } 

    // app state
    const [availableLayers, setAvailableLayers] = useState([])
    const [selectedFeatures, setSelectedFeatures] = useState([])
    const [selectedIntersections, setSelectedIntersections] = useState([])
    const [intersectionLayer, setIntersectionLayer] = useState([])
    const [intersection, setIntersection] = useState(null)
    const [fetchingIntersection, setFetchingIntersection] = useState(false)
    const [intersectionTable, setIntersectionTable] = useState([])
    const [intersectionTableVisibility, setIntersectionTableVisibility] = useState(true)
    const [mapLoaded, setMapLoaded] = useState(false)
    const [errorAPI, setErrorAPI] = useState(false)
    const [selectedArea, setSelectedArea] = useState(0)
    const [unit, setUnit] = useState('sqkm') // sqm: square meters, sqkm: sqkm
    const [help, setHelp] = useState(false)

    const [lng, setLng] = useState(mapcon.INIT_LONG);
    const [lat, setLat] = useState(mapcon.INIT_LAT);
    const [zoom, setZoom] = useState(mapcon.INIT_ZOOM);
    
    const calculateArea = (geometry, desiredUnit) => {

        let type = geometry.type.toUpperCase()
        let coordinates = geometry.coordinates
        let polygon;
        let area;
        switch(type) {
            case 'POLYGON':
                polygon = turf.polygon(coordinates);
                area = turf.area(polygon);
                return toUnit(area, desiredUnit)
            case 'MULTIPOLYGON':
                let areas = coordinates.map((c) => {
                    let p = turf.polygon(c);
                    let a = turf.area(p);
                    return a
                })
                area = areas.reduce((a, b) => a + b)
                return toUnit(area, desiredUnit);
            case 'GEOMETRYCOLLECTION': 
                area = geometry["geometries"].map((g) => {
                    return parseFloat(calculateArea(g, desiredUnit));
                }).reduce((a, b) => a + b)

                // since it is a aggregation of areas, no need to further 
                // transform to adequate unit.
                return area;
            default:
                return toUnit(0, desiredUnit)
        }

    }

    const toUnit = (num, u) => {
        if(u === 'sqm') return parseFloat(num).toFixed(2);
        else if(u === 'sqkm') return parseFloat(num*1e-6).toFixed(2); 

    }

    // Toggle layer
    const toggleLayer = (layerId) => {
        const visibility = map.current.getLayoutProperty(layerId, "visibility")
        if (visibility === "visible") {
            map.current.setLayoutProperty(layerId, "visibility", "none")
            if(layerId === "intersection") setIntersectionTableVisibility(false)
        } else {
            map.current.setLayoutProperty(layerId, "visibility", "visible")
            if(layerId === "intersection") setIntersectionTableVisibility(true)
        }
    }

    // Data and constants
    const teAatiData = UnfilteredDataLoader(con.GEODATASET_TE_AATI);    
    const territoriesData = UnfilteredDataLoader(con.GEODATASET_TERRITORIES);
    const [dataLoaded, setDataLoaded] = useState(false)


    // Intersect selection
    const intersectFeatures = async () => {
        let queryObj = {}
        setFetchingIntersection(true)
        selectedFeatures.forEach((f) => {
            let layerId = f[mapcon.LAYER_NAME]
            let featureId = f[mapcon.LAYER_FEATURE_IDENTIFIER]
            let layerConstants = layercon.LAYER_CONSTANTS
            let layerObj;
            if(Object.keys(queryObj).includes(layerId)) {
                layerObj = queryObj[layerId]
                let _query = layerObj["query"]
                _query.push(featureId)
                layerObj["query"] = _query
            } else {
                layerObj = {}
                let identifierKey = layerConstants[layerId][mapcon.LAYER_FEATURE_IDENTIFIER]
                layerObj["query"] = [featureId]
                layerObj[mapcon.LAYER_FEATURE_IDENTIFIER] = identifierKey
                layerObj["database"] = layerConstants[layerId][mapcon.DB_INFO]["database"]
                layerObj["table"] = layerConstants[layerId][mapcon.DB_INFO]["table"]
            }
            queryObj[layerId] = layerObj;
        });
        //  API URL syntax: 
        // intersect_features/<str:db_id_1>/<str:table_id_1>/<str:filter_1>/<str:db_id_2>/<str:table_id_2>/<str:filter_2>/
        let url = ""
        Object.keys(queryObj).forEach((k) => {
            let obj = queryObj[k]
            let featureIds = "(";
            obj["query"].forEach((f) => {
                featureIds = featureIds + `'${f}',`
            });
            featureIds = featureIds.replace(/.$/,")")

            let filter = `${obj[mapcon.LAYER_FEATURE_IDENTIFIER]}:${featureIds}`
            url = url + `${obj["database"]}/${obj["table"]}/${filter}/`
        })

        // make request to api
        if(url === "") return
        url = con.BQ_API_INTERSECT_FEATURES + url

        // Builds Header for auth
        let headers = constructHeaders()

        try {
            let res = await axios.get(url, {headers : headers})
            let data = res.data[con.DATA]
            setIntersection(data)
            setErrorAPI(false);
            setFetchingIntersection(false)
        } catch(err) {
            setIntersection([]);
            setErrorAPI(true);
            setFetchingIntersection(false);
        }
    }

    // build intersection table
    const buildIntersectionTable = (intersectionProperties) => {
        let header = intersectionProperties.map((p) => {
            let h;
            if(p === "area") h = `${p} ${unit}`
            else h = p
            return <th className='simple-table'>{h}</th>}
        )
        let selectedIds = selectedIntersections.map(i => i[mapcon.LAYER_FEATURE_IDENTIFIER])
        let body = intersection["features"].map((f, idx) => {
            let selected = false;
            if(selectedIds.includes(parseInt(f["id"]))) selected = true;

            let properties = f["properties"]

            if(selected) {
                return(
                    <tr key={f["id"]}>
                        <th className='highlighted-table' scope="row" >{f["id"]}</th>
                        {intersectionProperties.map(p => <th className='highlighted-table'>{properties[p] ? properties[p] : "N/A"}</th>)}
                    </tr>
                )
            } else {
                return(
                    <tr key={f["id"]}>
                        <th className='simple-table' scope="row" >{f["id"]}</th>
                        {intersectionProperties.map(p => <th className='simple-table'>{properties[p] ? properties[p] : "N/A"}</th>)}
                    </tr>
                )
            }
            
            
        })

        return (
            <table id='intersection-info'>
                <thead >
                    <tr key="header">
                        <th className='simple-table'>#</th>
                        {header}
                    </tr>
                </thead>
                <tbody>
                    {body}
                </tbody>
            </table>
            )
        
    }

    const numSelectedLayers  = () => {
        let layers = selectedFeatures.map((f) => {
            return f[mapcon.LAYER_NAME]
        });
        layers = layers.filter((value, index, self) => {return self.indexOf(value) === index});
        return layers.length
    }
    
    // add intersection source
    useEffect(() => {
        let layerId = "intersection"

        if (!intersection) return
        // add area property to intersecion
        intersection["features"].forEach((f) => {
            let geometry = f['geometry'];
            let area = calculateArea(geometry, unit);
            f["properties"]["area"] = area ;
        })        
        

        // intersection layer
        let newIntersectionLayer = {
            [mapcon.LAYER_NAME] : layerId,
            [mapcon.LEGEND_NAME] : "Intersección calculada",
            [mapcon.LAYER_DATA] : intersection,
            [mapcon.LAYER_FEATURE_IDENTIFIER] : "id",
            [mapcon.LAYER_FEATURE_NAME] : null,
            [mapcon.LAYER_COLOR] : layercon.INTERSECTION_COLOR,
            [mapcon.LAYER_VIZ_PARAMS] : layercon.INTERSECTION_VIZ_PARAMS
        }

        setIntersectionLayer([newIntersectionLayer])

        //  Add  sources
        if (!map.current.getSource(layerId)) {
            map.current.addSource(layerId, {
                type: 'geojson',
                data: intersection,
                generateId: true,

            });
        } else {
            map.current.getSource(layerId).setData(intersection);
        }
        // Add layers
        if(!map.current.getLayer(layerId)) {
            map.current.addLayer(layercon.INTERSECTION_VIZ_PARAMS);
            
        }

        // make sure its visible
        const visibility = map.current.getLayoutProperty(layerId, "visibility")
        if (visibility !== "visible") {
            map.current.setLayoutProperty(layerId, "visibility", "visible")
            setIntersectionTableVisibility(true)
        }



    }, [intersection, unit])

    // build table
    useEffect(() => {
        let newTable;
        if(intersection) newTable = buildIntersectionTable(Object.keys(intersection["features"][0]["properties"]))
        else newTable = <div></div>
        setIntersectionTable(newTable)
    }, [intersection, selectedIntersections, unit])

    // colors

    useEffect(() => {
        // set available layers
        if(!(Array.isArray(teAatiData) && !teAatiData.length) 
            && !(Array.isArray(territoriesData) && !territoriesData.length)){
            let initLayers = [
                {
                    [mapcon.LAYER_NAME] : layercon.TERRITORIOS_CONSTANTS[mapcon.LAYER_NAME],
                    [mapcon.LEGEND_NAME] : layercon.TERRITORIOS_CONSTANTS[mapcon.LEGEND_NAME],
                    [mapcon.LAYER_DATA] : territoriesData,
                    [mapcon.LAYER_FEATURE_IDENTIFIER] : layercon.TERRITORIOS_CONSTANTS[mapcon.LAYER_FEATURE_IDENTIFIER],
                    [mapcon.LAYER_FEATURE_NAME] : layercon.TERRITORIOS_CONSTANTS[mapcon.LAYER_FEATURE_NAME],
                    [mapcon.LAYER_COLOR] : layercon.TERRITORIOS_CONSTANTS[mapcon.LAYER_COLOR],
                    [mapcon.LAYER_VIZ_PARAMS] :  {
                        'id': layercon.TERRITORIOS_CONSTANTS[mapcon.LAYER_NAME],
                        'type': 'fill',
                        'source': layercon.TERRITORIOS_CONSTANTS[mapcon.LAYER_NAME],
                        'layout': {
                            // Make the layer visible by default.
                            'visibility': 'visible'
                        },
                        'paint': {
                            'fill-color': [
                                'case',
                                ['boolean',['feature-state', 'clicked'], false],
                                mapcon.FILL_COLOR, // if selected
                                layercon.TERRITORIOS_CONSTANTS[mapcon.LAYER_COLOR]
                            ],
                            'fill-opacity': 0.3
                        }
                    }
                },
                {
                    [mapcon.LAYER_NAME] : layercon.TE_AATI_CONSTANTS[mapcon.LAYER_NAME],
                    [mapcon.LEGEND_NAME] : layercon.TE_AATI_CONSTANTS[mapcon.LEGEND_NAME],
                    [mapcon.LAYER_DATA] : teAatiData,
                    [mapcon.LAYER_FEATURE_IDENTIFIER] : layercon.TE_AATI_CONSTANTS[mapcon.LAYER_FEATURE_IDENTIFIER],
                    [mapcon.LAYER_COLOR] : layercon.TE_AATI_CONSTANTS[mapcon.LAYER_COLOR],
                    [mapcon.LAYER_FEATURE_NAME] : layercon.TE_AATI_CONSTANTS[mapcon.LAYER_FEATURE_NAME],
                    [mapcon.LAYER_VIZ_PARAMS] :     {
                        'id': layercon.TE_AATI_CONSTANTS[mapcon.LAYER_NAME],
                        'type': 'fill',
                        'source': layercon.TE_AATI_CONSTANTS[mapcon.LAYER_NAME],
                        'layout': {
                            // Make the layer visible by default.
                            'visibility': 'visible'
                        },
                        'paint': {
                            'fill-color': [
                                'case',
                                ['boolean',['feature-state', 'clicked'], false],
                                mapcon.FILL_COLOR, // if selected
                                layercon.TE_AATI_CONSTANTS[mapcon.LAYER_COLOR] 
                            ],
                            'fill-opacity': 0.3
                        }
                    }
                },
            ];
            setAvailableLayers(initLayers);
            setDataLoaded(true);
        }

    }, [teAatiData, territoriesData])

    useEffect(() => {

        // wait to innit map until data is laoded
        if(!dataLoaded) return;

        // initialize map only once 
        if (!map.current) {
            map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: mapStyle,
            center: [lng, lat],
            zoom: zoom
            });
        }
    
        // interaction
        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });

        // check if map laoded
        map.current.on('load', () => { 

            availableLayers.forEach((layer) => {
                let layerId = layer[mapcon.LAYER_NAME]

                //  Add  sources
                if (!map.current.getSource(layerId)) {
                    map.current.addSource(layerId, {
                        type: 'geojson',
                        data: layer[mapcon.LAYER_DATA],
                        promoteId: layer[mapcon.LAYER_FEATURE_IDENTIFIER]
                    });
                }
                // Add layers
                if(!map.current.getLayer(layerId)) {
                    map.current.addLayer(layer[mapcon.LAYER_VIZ_PARAMS]);
                }
            })

            setMapLoaded(true);

        });

        
    }, [availableLayers, lat, lng, mapStyle, zoom, dataLoaded]);

    useEffect(() => {
        if (mapLoaded) {

            // First remove state from all features
            availableLayers.forEach((layer) => {
                let layerId = layer[mapcon.LAYER_NAME]
                const features = map.current.querySourceFeatures(layerId);
                features.forEach((f) => {
                    let removeId = f.id
                    map.current.setFeatureState({
                        source: layerId,
                        id: removeId
                    },  {
                        clicked: false
                    });
                });  
            });
            
            availableLayers.forEach((layer) => {

                let layerId = layer[mapcon.LAYER_NAME]
                // add click functionality
                map.current.on('click', layerId, (e) => {

                    // bind selector function (pass state to parent)
                    let featureIdendifier = layer[mapcon.LAYER_FEATURE_IDENTIFIER]
                    let featureNameIdentifier = layer[mapcon.LAYER_FEATURE_NAME]
                    let featureId = e.features[0]['properties'][featureIdendifier];
                    let featureName = e.features[0]['properties'][featureNameIdentifier];
                    let geometry = e.features[0]['geometry'];
                    let area = calculateArea(geometry, 'sqkm');

                    // set state for selected features
                    let newFeatureObj = {
                        [mapcon.LAYER_FEATURE_IDENTIFIER] : featureId,
                        [mapcon.LAYER_NAME] : layerId,
                        [mapcon.LAYER_FEATURE_NAME] : featureName,
                        area : area
                    }
                    
                    let newSelectedFeatures  = selectedFeatures.filter(f => 
                        f[mapcon.LAYER_FEATURE_IDENTIFIER] !== featureId || f[mapcon.LAYER_NAME] !== layerId   
                    )

                    if(newSelectedFeatures.length !== selectedFeatures.length) setSelectedFeatures(newSelectedFeatures)
                    else setSelectedFeatures([...selectedFeatures, newFeatureObj])
                });
            });


            // Next change selected features state within map
            let totalArea = 0
            selectedFeatures.forEach((f)  => {
                let layerId = f[mapcon.LAYER_NAME]
                let featureId = f[mapcon.LAYER_FEATURE_IDENTIFIER]
                if(layerId && featureId) 
                    map.current.setFeatureState({
                        source: layerId,
                        id: featureId,
                    }, {
                        clicked: true
                    });
                    // add area
                    totalArea = totalArea + parseInt(f['area'])
                });
            setSelectedArea(totalArea)
        
        }

        if(intersection) {
            // add click functionality
            let layerId = "intersection"
            map.current.on('click', layerId, (e) => {

                // bind selector function (pass state to parent)
                let featureId = e.features[0]['id'];
                let featureName = `Interseccion ${featureId}`;

                // set state for selected features
                let newFeatureObj = {
                    [mapcon.LAYER_FEATURE_IDENTIFIER] : featureId,
                    [mapcon.LAYER_NAME] : layerId,
                    [mapcon.LAYER_FEATURE_NAME] : featureName,
                }
                
                let newSelectedFeatures  = selectedIntersections.filter(f => 
                    f[mapcon.LAYER_FEATURE_IDENTIFIER] !== featureId || f[mapcon.LAYER_NAME] !== layerId   
                )

                if(newSelectedFeatures.length !== selectedIntersections.length) setSelectedIntersections(newSelectedFeatures)
                else setSelectedIntersections([...selectedIntersections, newFeatureObj])
            });

            // First remove state from all intersection features
            
            const features = map.current.querySourceFeatures(layerId);
            features.forEach((f) => {
                let removeId = f.id
                map.current.setFeatureState({
                    source: layerId,
                    id: removeId
                },  {
                    clicked: false
                });
            });  

            // Next change selected features state within map
            selectedIntersections.forEach((f)  => {
                let featureId = f[mapcon.LAYER_FEATURE_IDENTIFIER]
                if(layerId && featureId != null) 
                    map.current.setFeatureState({
                        source: layerId,
                        id: featureId,
                    }, {
                        clicked: true
                    });
                });
        }
    }, )

            
    return (
        <div className='column-container'>
            {!mapLoaded ? 
                <div className="loadingbar">
                    Loading map...
                </div> : errorAPI ? 
                <div className="loadingbar">
                    <b>Error al connectar con el API</b>
                </div> : fetchingIntersection ?
                <div className="loadingbar">
                Caculating intersection...
                </div> : <div></div>}
                {help ? 
                <div className='column-container'>
                    <button className='help-button' onClick={() => setHelp(false)}>Ayuda</button>
                    <div style={{alignSelf: "center", width: '33%'}}>
                        <p>Este aplicativo permite intersectar polígonos de capas diferentes. Usar los botones de selección de capas para prender 
                        y apagar las mismas, y usar el mapa para seleccionar los diferentes polígonos. Es necesario escoger por lo menos dos polígonos de 
                        dos capas diferentes.</p>
                    </div>
                </div> : <button className='help-button' onClick={() => setHelp(true)}>Ayuda</button>}
                <div ref={mapContainer} className="map-container" style={{alignSelf: "center", width: width, height: height}} >
                {includeBar ? 
                    <div className="sidebar">
                        Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
                    </div> : <div></div>}
                </div>
                                
                <div className='row-container'>
                    {availableLayers.concat(intersectionLayer).map((l) => {
                        return <button 
                            className='layer-toggle'
                            key={l[mapcon.LAYER_NAME]} 
                            onClick={() => toggleLayer(l[mapcon.LAYER_NAME])} 
                            style={{backgroundColor: l[mapcon.LAYER_COLOR]}}>
                                {l[mapcon.LEGEND_NAME]}
                            </button>
                    })}
                </div>
                <div className='column-container'> 
                <div className='row-container'>
                    <div className='stretch-4-12'> 
                        {(fetchingIntersection || selectedIntersections.length > 0 || 
                        selectedFeatures.length < 2) || numSelectedLayers() <= 1 ? 
                        <button className='disabled'>
                            Intersectar selección
                        </button> : <button className='submit' onClick={() => intersectFeatures()}>
                            Intersectar selección
                        </button>}
                    </div>
                    <div className='stretch-4-12'> 
                        <button className='submit' onClick={() => {
                            unit === 'sqm' ? setUnit('sqkm') : setUnit('sqm')}}>
                            {unit === "sqkm" ? <div>km<sup>2</sup></div> : <div>m<sup>2</sup></div>}
                        </button>
                    </div>
                    <div className='stretch-4-12'> 
                        {(selectedIntersections.length === 0 && selectedFeatures.length === 0) ? 
                        <button className='disabled'>
                            Borrar selección
                        </button> : <button className='submit' onClick={() => {
                            setSelectedFeatures([]);
                            setSelectedIntersections([]);
                            }}>
                            Borrar selección
                        </button>}
                    </div>
                    
                </div>
                <div className='column-container'>
                    <div style={{alignSelf: 'center'}}>
                    {intersectionTableVisibility ? intersectionTable : <div></div>}
                    </div>
                    <div style={{alignSelf: 'center'}}>
                        <table>
                            <thead className='map-info'>
                                <tr hey="header">
                                    <th>#</th>
                                    <th>Capa</th>
                                    <th>Identificador del objeto</th>
                                    <th>Nombre del objeto</th>
                                    {unit === "sqkm" ? <th>Area (km<sup>2</sup>)</th> :  <th>Area (m<sup>2</sup>)</th> }
                                </tr>
                            </thead>
                            <tbody>
                                {selectedFeatures.map((f, idx) => {
                                    return(
                                        <tr key={idx}>
                                        <th scope="row">{idx}</th>
                                        <th>{f[mapcon.LAYER_NAME]}</th>
                                        <th>{f[mapcon.LAYER_FEATURE_IDENTIFIER]}</th>
                                        <th>{f[mapcon.LAYER_FEATURE_NAME]}</th>
                                        <th>{unit == 'sqkm' ? f["area"] : f["area"]*1000000}</th>
                                        </tr>
                                )
                                })}
                                <tr key={selectedFeatures.length}>
                                <th scope="row"></th>
                                <th></th>
                                <th></th>
                                <th>Area total</th>
                                <th>{unit == 'sqkm' ? selectedArea : selectedArea*1000000}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}
