// Database manager
// Single entrypoint for the entire application for data loading
import * as con from "../Constants"
import { setApplicationData } from "../store/actions/applicationData";
import { getApplicationParameter } from "../store/actions/applicationParameters";


// Loaders
import SigetiApiDataLoader from "./loaders/SigetiApiDataLoader"
import LocalDataLoader from "./loaders/LocalDataLoader"

// Datasets
import selectedDataSets from "./selectedDataSets.json"
const DB_TO_FILE_MAP = selectedDataSets

const onlineModule = SigetiApiDataLoader
const offlineModule = LocalDataLoader

const selectedTerritories = con.CONFIG[con.SELECTED_TERRITORIES]

// Loads the entire data store
export const loadAllDataIntoStore = async () =>
{

    let isOnline = getApplicationParameter(con.IS_ONLINE)

    const selectedModule = isOnline ? onlineModule : offlineModule

    // Iterates over the available databases
    con.ALL_AVAILABLE_DATASETS.forEach(async dataSetId => {

        let dbObject = DB_TO_FILE_MAP[dataSetId]
        let format = dbObject[con.FORMAT]

        if(selectedModule.canLoadDataSet(dataSetId))
        {
            // Loads
            let db = await selectedModule.loadSingleDataSetIntoStore(dataSetId)

            // Filters if json
            if(format === con.JSON) {
                db = db.filter(ob => selectedTerritories === null || selectedTerritories.includes(ob[con.TERRITORY_ID]))
            }
            setApplicationData(dataSetId, db);

        }
        else
            console.error(`Current ${isOnline ? "online":"offline"} module can't load the dataset with id: ${dataSetId}`)

    })
}

