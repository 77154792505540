import React from 'react'
//import { Link } from "react-router-dom"

import * as con from "../Constants"
import SexBarChartByTerritory from '../components/charts/SexBarChartByTerritory';
import AgeSexBarChartByTerritory from '../components/charts/AgeSexBarChartByTerritory';
import { RefApplicationParameterHook } from '../components/hooks/ApplicationParamatersHook';
import { getTerritoryByID } from '../utils/dataFunctions';
import { TotalChagras, TotalCommunities, TotalElders, TotalEthnicities, TotalFamilies, TotalMalocas, TotalPeople } from '../components/summaries/GeneralStatistics';

//import logoSigeti from "../static/img/sigeti.png"
//import logoGaia from "../static/img/gaia.png"
import EthnicBarChartByTerritory from '../components/charts/EthnicBarChartByTerritory';
import EducationPieChartByTerritory from '../components/charts/EducationPieChartByTerritory';
import EducationBarChartByTerritory from '../components/charts/EducationBarChartByTerritory';
import { Text } from '@blueprintjs/core';
import LanguagesBarChartByTerritory from '../components/charts/LanguagesBarChartByTerritory';
import FamiliesBarChartByTerritory from '../components/charts/FamiliesBarChartByTerritory';


function TerritoryGeneralView() {


    const territoryId = RefApplicationParameterHook(con.SELECTED_TERRITORY_ID)

  return (
    <div style={{padding : "0vh"}}>
        <div className="parameterBox">
          {/* <div style={{height : "65px", width:"120px"}}>
            <Link to={con.PATH_HOME}>
              <img src={logoSigeti} height="100%" alt="logo sigeti"/>
            </Link>
          </div>             */}
            <h1 style={{color : "var(--title-color)", marginBottom : "0.5vh", marginTop : "0.5vh"}}>{getTerritoryByID(territoryId)[con.TERRITORY_NAME] + " (Censo 632)"}</h1>            
            {/* <div style={{height : "60px", width:"120px"}}>
              <img src={logoGaia} height="65px" alt="logo gaia"/>
            </div> */}
        </div>
        <div style={{height : "0.2vh", backgroundColor : "green", opacity:0.7}}></div>
        <div className="parameterBoxCenterd" style={{marginBottom : "1vh", marginTop : "0.5vh"}}>
            <TotalCommunities />            
            <TotalFamilies/> 
            <TotalEthnicities />
            <TotalElders />
            <TotalPeople />     
            <TotalMalocas />            
            <TotalChagras />     
        </div>
        <div className="parameterBox">     
          <SexBarChartByTerritory/>         
          <AgeSexBarChartByTerritory />
        </div>
    
      <div className="parameterBox" style={{marginTop : "1vh"}}>
        <EthnicBarChartByTerritory />
      </div>

      <div className="parameterBox" style={{marginTop : "1vh"}}>
        <FamiliesBarChartByTerritory />
      </div>

      <div className="parameterBox" style={{marginTop : "1vh"}}>
        <LanguagesBarChartByTerritory />
      </div>

    <Text style={{fontSize : "24px", marginTop : "5vh"}}>Educación</Text>
      <div className="parameterBox" >
        <div style={{width : "33%"}}>
          <EducationPieChartByTerritory />
        </div>
        <div style={{width : "67%"}}>
          <EducationBarChartByTerritory />
        </div>
      </div>
    </div>
  )
}

export default TerritoryGeneralView