import React, { useEffect, useMemo, useRef } from 'react'

import PieChart from '../../containers/d3Charts/PieChart'

import * as con from "../../Constants"
import { RefApplicationParameterHook } from '../hooks/ApplicationParamatersHook'

import TerritoryDataLoader from '../data/TerritoryDataLoader'
import { educationGroupSortingFunction, translateEducationGroup } from '../../utils/translateFunctions'


function EducationPieChartByTerritory({includeTerritoryInTitle}) {


        // Object Reference
        const objectReference = useRef()

        // Selected Community and Territory
        const territoryId = RefApplicationParameterHook(con.SELECTED_TERRITORY_ID)

        // Initial Parameters
        const  parameters = useMemo(() => {return({ 
            nameExtractor : d => translateEducationGroup(d.grupo_escolar),
            valueExtractor : d => d.total,
            sortingFunction : (a,b) => educationGroupSortingFunction(a.grupo_escolar, b.grupo_escolar)        
        })},  [])
        
       
        // Territory data
        const dataTerritories = TerritoryDataLoader(con.DATASET_EDUCATION_TERRITORY)

        // Display Mode
        const displayMode = RefApplicationParameterHook(con.DISPLAY_MODE)


        useEffect(() => {
            
            
            if(dataTerritories.length > 0) 
                PieChart.build(objectReference, dataTerritories, parameters, displayMode)

        }, [dataTerritories, territoryId, parameters, displayMode])

        

        return (
            <div style={{marginLeft : "2%", marginRight : "2%"}}>
                {dataTerritories.length > 0 && <svg ref={objectReference} />}
        </div>
        );

}

EducationPieChartByTerritory.defaultProps = {
    includeTerritoryInTitle : false
}


export default EducationPieChartByTerritory