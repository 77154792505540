import { sum } from 'd3-array'
import React from 'react'
import * as con from '../../Constants'
import CustomCard from '../../containers/cards/CustomCard'
import CommunityDataLoader from '../data/CommunityDataLoader'
import TerritoryDataLoader from '../data/TerritoryDataLoader'


// General elements
// ------------------
export function TotalStatistic({type, title,statisticId, color}) {

    if(type === con.TERRITORY)
      return (<TotalTerritoryStatistic  title={title} statisticId={statisticId} color = {color}/>)
    else
      return (<TotalCommunityStatistic title={title} statisticId={statisticId} color = {color}/>)
}

TotalStatistic.defaultProps = {
  type : con.TERRITORY,
  color : 'grey',
}

export function TotalTerritoryStatistic({title,statisticId, color}) {

    // Territory data
    const data = TerritoryDataLoader(con.DATASET_GENERAL_DATA_TERRITORY)


    return (<CustomCard title={title} value={sum(data.map(ob => ob[statisticId]))} color = {color}/>)
}

export function TotalCommunityStatistic({title,statisticId, color}) {

  // Community data
  const data = CommunityDataLoader(con.DATASET_GENERAL_DATA_COMMUNITY)

  return (
    <CustomCard title={title} value={sum(data.map(ob => ob[statisticId]))} color = {color}/>
  )
}


// Specific elements
// ------------------

export function TotalCommunities(parameters) {
    return (<TotalTerritoryStatistic {...parameters}  title="Comunidades" statisticId={con.TOTAL_COMMUNITIES} color="rgba(var(--people-color-rgb-nums),0.9)"/>)
}

export function TotalClans(parameters) {
  return (<TotalStatistic {...parameters}  title="Clanes" statisticId={con.TOTAL_CLANS}/>)
}

export function TotalEthnicities(parameters) {
  return (<TotalStatistic {...parameters}  title="Pueblos" statisticId={con.TOTAL_ETHNICITIES} color="rgba(var(--people-color-rgb-nums),0.7)"/>)
}

export function TotalFamilies(parameters) {
  return (<TotalStatistic {...parameters}  title="Familias" statisticId={con.TOTAL_FAMILIES}  color="rgba(var(--people-color-rgb-nums),0.9)"/>)
}


export function TotalElders(parameters) {
  return (<TotalStatistic {...parameters}  title="Sabedores" statisticId={con.TOTAL_ELDERS} color="rgba(var(--people-color-rgb-nums),0.5)"/>)
}


export function TotalPeople(parameters) {
  return (<TotalStatistic {...parameters}  title="Personas" statisticId={con.TOTAL_PEOPLE} color="rgba(var(--people-color-rgb-nums),0.3)"/>)
}

export function TotalResidents(parameters) {
  return (<TotalStatistic {...parameters}  title="Residentes" statisticId={con.TOTAL_PEOPLE_RESIDENT} color="rgba(var(--people-color-rgb-nums),0.2)"/>)
}

export function TotalNonResidents(parameters) {
  return (<TotalStatistic {...parameters}  title="No Residentes" statisticId={con.TOTAL_PEOPLE_NOT_RESIDENT} color="rgba(var(--people-color-rgb-nums),0.2)"/>)
}

export function TotalChagras(parameters) {
  return (<TotalStatistic {...parameters}  title="Chagras" statisticId={con.TOTAL_CHAGRAS} color="var(--infrastructure-color)"/>)
}

export function TotalFamilyChagras(parameters) {
  return (<TotalStatistic {...parameters}  title="Chagras Familiares" statisticId={con.TOTAL_CHAGRAS_FAMILY} color="var(--infrastructure-color)"/>)
}

export function TotalCommunityChagras(parameters) {
  return (<TotalStatistic {...parameters}  title="Chagras Comunitarias" statisticId={con.TOTAL_CHAGRAS_COMMUNITY} color="var(--infrastructure-color)"/>)
}

export function TotalMalocas(parameters) {
  return (<TotalStatistic {...parameters}  title="Malocas" statisticId={con.TOTAL_MALOCAS} color="var(--infrastructure-color)"/>)
}


