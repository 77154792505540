import React, { useEffect, useState } from 'react';

import BasicMap from '../../containers/mapbox/BasicMap'
import * as con from '../../Constants'
import * as mapcon from '../../containers/mapbox/mapconstants'
import UnfilteredDataLoader from '../data/UnfilteredDataLoader';
import { getCommunityByID, getTerritoryByID } from '../../utils/dataFunctions';
import { StateApplicationParameterHook, RefApplicationParameterHook } from '../../components/hooks/ApplicationParamatersHook';


export default function DefrestationMap({includeTerritoryInTitle}) {

    const communitiesData = UnfilteredDataLoader(con.GEODATASET_COMMUNITIES);
    const territoriesData = UnfilteredDataLoader(con.GEODATASET_TERRITORIES);

    // Selected Community and Territory
    const [communityId, setCommunityId] = StateApplicationParameterHook(con.SELECTED_COMMUNITY_ID)
    const [territoryId, setTerritoryId] = StateApplicationParameterHook(con.SELECTED_TERRITORY_ID)

    // Build layers
    let [layers, setLayers] = useState(null);

    // colors
    const communityColor = "#cccccc"
    const territoryColor = "#cccccc"
    const emptyColor = "#FFFFFF"
    const neutralColor = "#1a1a1a"
    const deforestationColors = ["#73A9AD", "#90C8AC", "#C4DFAA", "#F5F0BB", "#D61C4E", emptyColor]

    useEffect(() => {
        // set layers
        if(!(Array.isArray(communitiesData) && !communitiesData.length) 
            && !(Array.isArray(territoriesData) && !territoriesData.length)){
            let initLayers = [
                {
                    [mapcon.LAYER_NAME] : "territorios",
                    [mapcon.LAYER_DATA] : territoriesData,
                    [mapcon.LAYER_FEATURE_IDENTIFIER] : con.TERRITORY_ID,
                    [mapcon.LAYER_VIZ_PARAMS] :     {
                        'id': 'territorios',
                        'type': 'fill',
                        'source': 'territorios',
                        'paint': {
                            'fill-color': [
                                'case',
                                ['boolean',['feature-state', 'clicked'], false],
                                mapcon.FILL_COLOR, // if selected
                                territoryColor
                            ],
                            'fill-opacity': 0.3
                        }
                    }
                },
                {
                    [mapcon.LAYER_NAME] : "comunidades",
                    [mapcon.LAYER_DATA] : communitiesData,
                    [mapcon.LAYER_FEATURE_IDENTIFIER] : con.COMMUNITY_ID,
                    [mapcon.LAYER_VIZ_PARAMS] :     {
                        'id': 'comunidades',
                        'type': 'circle',
                        'source': 'comunidades',
                        'paint': {
                            'circle-radius': 6,
                            'circle-color': [
                                'case',
                                ['boolean',['feature-state', 'clicked'], false],
                                mapcon.FILL_COLOR, // if selected
                                communityColor 
                            ],
                        }
                    }
                },
            ];
            setLayers(initLayers);
        }

    }, [communitiesData, territoriesData])

    // define selector functions
    function selectTerritoryFromMap(feautureId) {
        if(getTerritoryByID(feautureId)) {
            setTerritoryId(feautureId);
        }
    };

    function selectCommunityFromMap(feautureId) {
        if(getCommunityByID(feautureId)) {
            setCommunityId(feautureId);
        }
    };

    const selectorFunctions = [
        {
            [mapcon.SELECTOR_LAYER_ID] : 'territorios',
            [mapcon.SELECTOR_FUNCTION] : t => selectTerritoryFromMap(t),
            [mapcon.SELECTOR_PARAMETER] : con.SELECTED_TERRITORY_ID,
            [mapcon.SELECTOR_PARAMETER_HOOK] : d => RefApplicationParameterHook(d),
            [mapcon.LAYER_FEATURE_IDENTIFIER] : con.TERRITORY_ID,
        },{
            [mapcon.SELECTOR_LAYER_ID] : 'comunidades',
            [mapcon.SELECTOR_FUNCTION] : c => selectCommunityFromMap(c),
            [mapcon.SELECTOR_PARAMETER] : con.SELECTED_COMMUNITY_ID,
            [mapcon.SELECTOR_PARAMETER_HOOK] : d => RefApplicationParameterHook(d),
            [mapcon.LAYER_FEATURE_IDENTIFIER] : con.COMMUNITY_ID,
        }
]


    return (
        <div className='row-container'>
            <div className='strech-10-12'>
                <BasicMap 
                    includeBar={false} 
                    layers={layers} 
                    selectors={selectorFunctions} 
                    mapBackground="mapbox://styles/andrea-parra/cl63p5103001v14l877k0v77j"/>
            </div>
            <div className="column-container">
                <div>
                    <div style={{marginBottom: "5px"}}>
                        Año de perdida de bosque
                    </div>
                    <svg height="300px" width="50px" style={{alignSelf: "center"}}>
                        <defs>
                        <linearGradient id="gradient-raster" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" stopColor={deforestationColors[4]}/>
                            <stop offset="20%" stopColor={deforestationColors[3]} />
                            <stop offset="40%" stopColor={deforestationColors[2]} />
                            <stop offset="80%" stopColor={deforestationColors[1]} />
                            <stop offset="100%" stopColor={deforestationColors[0]} />
                        </linearGradient>
                        </defs>
                        <rect fill="url(#gradient-raster)" width="30%" height="100%"/>  
                        <text x="40%" y="3%" fontFamily="Verdana" fontSize="10" fill={neutralColor}>2021</text>      
                        <text x="40%" y="20%" fontFamily="Verdana" fontSize="10" fill={neutralColor}>2016</text>      
                        <text x="40%" y="40%" fontFamily="Verdana" fontSize="10" fill={neutralColor}>2012</text>      
                        <text x="40%" y="60%" fontFamily="Verdana" fontSize="10" fill={neutralColor}>2008</text>
                        <text x="40%" y="80%" fontFamily="Verdana" fontSize="10" fill={neutralColor}>2004</text>      
                        <text x="40%" y="100%" fontFamily="Verdana" fontSize="10" fill={neutralColor}>2000</text>
                    </svg>  
                </div>
            </div>
        </div>
    )
}
