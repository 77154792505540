import React, { useRef, useEffect, useState } from 'react';

import NetworkChart from '../../containers/d3Charts/NetworkChart'
import * as con from '../../Constants'
import UnfilteredDataLoader from '../data/UnfilteredDataLoader';
import { StateApplicationParameterHook } from '../../components/hooks/ApplicationParamatersHook';

function LenguasNetworkChartByTerritory({includeTerritoryInTitle}) {

    // Chart constants
    const ID = 'id';
    const GROUP = 'group';
    const SOURCE = 'source';
    const TARGET = 'target';
    const VALUE = 'value';

    // Display Mode
    const displayMode = 'pedagogic';

    // Lengua tradicional o occidental
    const groups = {
        0: con.LENGUA_TRADICIONAL,
        1: con.LENGUA_OCCIDENTAL
    }
    const [selectedGroups, setSelectedGroups] = useState(Object.keys(groups).map((key) => {
        if(key) {
            return parseInt(key)
        }
        return null;
    }));

    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);

    // Selected language
    const [selectedLanguage, setSelectedLanguage] = StateApplicationParameterHook(con.SELECTED_LANGUAGE)

    // Object Reference
    const objectReference = useRef()
    
    // Lenguas data
    const nodesData = UnfilteredDataLoader(con.DATASET_LENGUAS_NODES)
    const edgesData = UnfilteredDataLoader(con.DATASET_LENGUAS_EDGES)

    function renameKeys(obj, newKeys) {
        const keyValues = Object.keys(obj).map(key => {
          const newKey = newKeys[key] || key;
          return { [newKey]: obj[key] };
        });
        return Object.assign({}, ...keyValues);
      }

    // Change keys
    let cleanNodes = nodesData.map((obj) => {
        let newKeyList = {
            [con.LENGUAS_NODE_ID]: ID,
            [con.LENGUAS_NODE_GROUP]: GROUP}
        let newObject = renameKeys(obj, newKeyList);
        return newObject
    }).filter(obj => (obj[ID]))

    // code groups as int
    let groupCodes = {[con.LENGUA_TRADICIONAL]: 1, [con.LENGUA_OCCIDENTAL]: 0}
    cleanNodes = cleanNodes.map((obj) => {
        let code = groupCodes[obj[GROUP]]
        
        return {...obj, group: code}
    })
    
    // Change keys
    let cleanEdges = edgesData.map((obj) => {
        let newKeyList = {
            [con.LENGUA_1]: SOURCE,
            [con.LENGUA_2]: TARGET,
            [con.LENGUA_TOT]: VALUE}
        let newObject = renameKeys(obj, newKeyList);
        return newObject
    })

    // update state when data is cleaned
    if(nodes.length === 0 && cleanNodes.length !== 0 && selectedGroups.length > 0) {
        setNodes(cleanNodes);
    }

    if(edges.length === 0 && cleanEdges.length !== 0 && selectedGroups.length > 0) {
        setEdges(cleanEdges);
    }

    const handleChange = (i) => {
        let id = parseInt(i);

        let newSelectedGroups = selectedGroups
        if (selectedGroups.includes(id)) {
            const index = selectedGroups.indexOf(id);
            newSelectedGroups.splice(index, 1); 
        } else {
            newSelectedGroups.push(id)
        }
        setSelectedGroups(newSelectedGroups)

        // filter edges and nodes
        let filteredeNodes = cleanNodes.filter(obj => !newSelectedGroups.includes(obj[GROUP]))
        let filteredeNodesIds = filteredeNodes.map((obj) => {return obj[ID]})
        let filteredEdges = cleanEdges.filter(obj => 
            (filteredeNodesIds.includes(obj[TARGET]) && filteredeNodesIds.includes(obj[SOURCE]))
        );

        setEdges(filteredEdges);
        setNodes(filteredeNodes);
      };


    useEffect(() => {

        const nodeColors = ["var(--lenguas-occidentales-color)", "var(--lenguas-tradicionales-color)"];
        const edgeColor = 'var(--network-edges-color)';

        // Initial Parameters
        const parameters = {
            title : "Red de lenguas",
            edgeColor: edgeColor,
            groupColors: nodeColors,
        }

        if(nodes.length > 0) 

            NetworkChart.build(objectReference, nodes, edges, setSelectedLanguage, parameters, displayMode)

    }, [objectReference, nodes, edges, displayMode])

    return (
        <div>
            <div>
            <label>
                <input type="checkbox" id={0} checked={selectedGroups.includes(0)} onChange={() => {handleChange(0)}}/>
                {"Lengua " + groups[0]}
            </label>
            <label>
                <input type="checkbox" id={1} checked={selectedGroups.includes(1)} onChange={() => {handleChange(1)}}/>
                {"Lengua " + groups[1]}
            </label>
            </div>
            <div style={{width : "100%"}}>
                {nodes.length > 0 && <svg ref={objectReference} />}
            </div>
        </div>

    );

    

}

export default LenguasNetworkChartByTerritory