import React from 'react'
import {ThreeDots} from 'react-loading-icons'

function LoadingIcon() {
    return (
        <div>
            <ThreeDots speed={0.6} stroke="grey"/>
        </div>
    )
}


export default LoadingIcon
