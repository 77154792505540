import { useEffect, useState } from 'react'
import * as con from "../../Constants"
import { RefApplicationArrayDataHook } from '../hooks/ApplicationDataHook'
import { RefApplicationParameterHook } from '../hooks/ApplicationParamatersHook'

function TerritoryDataLoader(dataSetId) {
  
    // Selected Territory
    const selectedTerritoryId = RefApplicationParameterHook(con.SELECTED_TERRITORY_ID)
 
    // Selected DataSet
    const dataSet = RefApplicationArrayDataHook(dataSetId)

    // All and filtered data
    const [data, setData] = useState(() => [])

    // Updates the filter
    useEffect(() => {

        if(dataSet.length > 0 && selectedTerritoryId !== null)
            setData(dataSet.filter(ob => ob[con.TERRITORY_ID] === selectedTerritoryId))
    

    }, [dataSet, selectedTerritoryId])
    
    
    return(data)

  
}

export default TerritoryDataLoader

