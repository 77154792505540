import React, { useEffect, useMemo, useRef } from 'react'


import LineChart from '../../containers/d3Charts/LineChart';

import * as con from "../../Constants"
import { RefApplicationParameterHook } from '../hooks/ApplicationParamatersHook'

import UnfilteredDataLoader from '../data/UnfilteredDataLoader';


function DeforestationChangeLineChart({includeTerritoryInTitle}) {

        // Object Reference
        const objectReference = useRef()

        
        // Display Mode
        const displayMode = RefApplicationParameterHook(con.DISPLAY_MODE);

        // Selected Community
        const communityId = RefApplicationParameterHook(con.SELECTED_COMMUNITY_ID);

        // Initial Parameters
        var  parameters = useMemo(() => {return({ 
            x : d => d.year,
            y : d => d.porcentaje_cambio_deforestacion,
            z : d => d[con.COMMUNITY_ID],
            title : d => `${d[con.COMMUNITY_NAME]}, ${d[con.TERRITORY_NAME]}`,
            chartTitle : "Cambio de deforestación por comunidad con base al año 2000",
            yLabel : "Porcentaje de cambio",
            xLabel : "Año",
        })}, [])

        // deforestation data
        const data = UnfilteredDataLoader(con.DATASET_DEFORESTATION_COMMUNITY)

        useEffect(() => {

            if(data.length > 0) { 
                
                LineChart.build(objectReference, data, communityId, parameters, displayMode)
            }
            

        }, [data, communityId, parameters, displayMode])

        return (
            <div>
                {data.length > 0 && <svg ref={objectReference} />}
            </div>
        );

}

DeforestationChangeLineChart.defaultProps = {
    includeTerritoryInTitle : false,
}


export default DeforestationChangeLineChart