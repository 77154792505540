export const INIT_LAT = -0.15;
export const INIT_LONG = -71.03;
export const INIT_ZOOM = 3.5;

export const DEFAULT_WIDTH = 400;
export const DEFAULT_HEIGHT = 400;

export const MAP_STYLE = "mapbox://styles/andrea-parra/clcqfu74y00b414lc1d3jdhst";
export const LAYER_NAME = "name";
export const LAYER_DATA = "data";
export const LAYER_VIZ_PARAMS = "viz_params";
export const LEGEND_NAME = "legendName";

// Function to be excecuted when a feature is clicked
export const SELECTOR_FUNCTION = "onClickFun";

// Id of the map layer 
export const SELECTOR_LAYER_ID = "layerId";

// Id of the selected feature
export const SELECTED_IDENTIFIER = "selectedId"

// layer color
export const LAYER_COLOR = "layer_color";

// Name of obj property that identifies features
export const LAYER_FEATURE_IDENTIFIER = "featureId";
export const LAYER_FEATURE_NAME = "featureName"

// application level or parent component level parameter that is to be passed to parameter hook to get state
export const SELECTOR_PARAMETER = "selectorParam"; 

// application level or parent component level function to set parameter as state
export const SELECTOR_PARAMETER_HOOK = "selectorParamHook"; 

// DB info
export const DB_INFO = "db_info"

export const FILL_COLOR = "#d66f7b";
export const FEATURE_COLOR = "#186251";