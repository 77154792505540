
import * as XLSX from 'xlsx';

import * as con from "../Constants"
import { getApplicationParameter } from "../store/actions/applicationParameters"
import datasets from "../database/selectedDataSets.json"


export const getTerritoryByID = (territoryId) =>
{

    const territories = getApplicationParameter(con.AVAILABLE_TERRITORIES)

    if(territoryId in territories)
        return(territories[territoryId])

    return(null)
}

export const getCommunityByID = (communityId) =>
{
    const communities = getApplicationParameter(con.AVAILABLE_COMMUNITIES)

    if(communityId in communities)
        return(communities[communityId])

    return(null)
}

export const getDBInfo = (databaseId) => 
{
    let datasetObj = datasets[databaseId]
    return datasetObj
}



export const downloadExcelFromArray = (array, filename = "Resumen") =>{

    const worksheet = XLSX.utils.json_to_sheet(array);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
    XLSX.writeFile(workbook, filename + ".xlsx");
}

export const downloadJSON = (obj, filename = "Resumen") => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
        JSON.stringify(obj)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = `${filename}.json`;

    link.click();
}