import {map, curveLinear, format, line as d3Line, group, pointer, least, extent, range, InternSet, scaleLinear, axisLeft, select, axisBottom, max, min} from "d3"
import * as charCon from "./chartConstants";


class LineChart{
    static build = (objectReference, data, selectedCommunity,
                            {
                                x = d => d, // x-axis data
                                y = d => d, // y-axis data
                                z = d => d, // series
                                chartTitle,
                                title, // given d in data, returns the title text
                                defined, // for gaps in data
                                curve = curveLinear, // method of interpolation between points
                                marginTop = 20, // top margin, in pixels
                                marginRight = 30, // right margin, in pixels
                                marginBottom = 50, // bottom margin, in pixels
                                marginLeft = 40, // left margin, in pixels
                                width = 640, // outer width, in pixels
                                height = 400, // outer height, in pixels
                                xDomain, // [xmin, xmax]
                                xRange = [marginLeft, width - marginRight], // [left, right]
                                xLabel,
                                yType = scaleLinear, // type of y-scale
                                yDomain, // [ymin, ymax]
                                yRange = [height - marginBottom, marginTop], // [bottom, top]
                                yFormat, // a format specifier string for the y-axis
                                yLabel, // a label for the y-axis
                                zDomain, // array of z-values
                                color = "currentColor", // stroke color of line, as a constant or a function of *z*
                                strokeLinecap, // stroke line cap of line
                                strokeLinejoin, // stroke line join of line
                                strokeWidth = 1.5, // stroke width of line
                                strokeOpacity, // stroke opacity of line
                                mixBlendMode = "multiply", // blend mode of lines
                                selectedColor = "rgb(255, 153, 153)",
                                passiveSelectedColor = "rgb(89, 89, 89)",
                                neutralColor = "rgb(217, 217, 217, 0.3)",
                                voronoi = false

                            } = {}, displayMode = charCon.REGULAR) => {

        // Creates the SVG
        const svgEl = select(objectReference.current)

        // Clean char
        svgEl.selectAll("*").remove()

        // Compute values.
        const X = map(data, x);
        const Y = map(data, y);
        const Z = map(data, z);
        const O = map(data, d => d);
        if (defined === undefined) defined = (d, i) => !isNaN(X[i]) && !isNaN(Y[i]);
        const D = map(data, defined);

        // Compute default domains, and unique the z-domain.
        if (xDomain === undefined) xDomain = extent(X);
        if (yDomain === undefined) yDomain = [0, max(Y, d => typeof d === "string" ? +d : d)];
        if (zDomain === undefined) zDomain = Z;
        zDomain = new InternSet(zDomain);

        // Omit any data not present in the z-domain.
        const I = range(X.length).filter(i => zDomain.has(Z[i]));

        // Construct scales and axes.
        const xScale = scaleLinear()
            .domain([min(X), max(X)])
            .rangeRound(xRange)

        const yScale = yType(yDomain, yRange);
        const xAxis = axisBottom(xScale)
            .ticks(width / 80).tickSizeOuter(0)
            .tickFormat(format("d"));

        const yAxis = axisLeft(yScale).ticks(height / 60, yFormat);

        // Compute titles.
        const T = title === undefined ? Z : title === null ? null : map(data, title);

        // Construct a line generator.
        const line = d3Line()
            .defined(i => D[i])
            .curve(curve)
            .x(i => xScale(X[i]))
            .y(i => yScale(Y[i]));

        

        // add chart title
        svgEl.append("text")
            .attr("x", width / 2)
            .attr("y", 25)
            .attr('text-anchor', 'middle')
            .style('font-size', 12 + "px")
            .text(chartTitle)

        svgEl.attr("width", width)
            .attr("height", height)
            .attr("viewBox", [0, 0, width, height])
            .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
            .style("-webkit-tap-highlight-color", "transparent")
            .on("pointerenter", pointerentered)
            .on("pointermove", pointermoved)
            .on("pointerleave", pointerleft)
            .on("touchstart", event => event.preventDefault());

        svgEl.append("g")
            .attr("transform", `translate(0,${height - marginBottom})`)
            .call(xAxis)
                .selectAll("text")  
                .style("text-anchor", "end")
                .attr("dx", "-.8em")
                .attr("dy", "0.5em")
                .attr("transform", "rotate(-45)")
                .style("font-size",10+"px");

        svgEl.append("text")
            .attr("x", width / 2)
            .attr("y", height)
            .attr("fill", "currentColor")
            .style("font-size",12+"px")
            .text(xLabel);

      
        svgEl.append("g")
            .attr("transform", `translate(${marginLeft},0)`)
            .call(yAxis)
            .call(g => g.select(".domain").remove())
            .call(voronoi ? () => {} : g => g.selectAll(".tick line").clone()
                .attr("x2", width - marginLeft - marginRight)
                .attr("stroke-opacity", 0.1))
            .call(g => g.append("text")
                .attr("x", -height/2 + marginBottom)
                .attr("y", -marginRight)
                .attr("fill", "currentColor")
                .attr('transform', "rotate(-90)")
                .style("font-size",12+"px")
                .text(yLabel));
      
        const path = svgEl.append("g")
            .attr("fill", "none")
            .attr("stroke", typeof color === "string" ? color : null)
            .attr("stroke-linecap", strokeLinecap)
            .attr("stroke-linejoin", strokeLinejoin)
            .attr("stroke-width", strokeWidth)
            .attr("stroke-opacity", strokeOpacity)
          .selectAll("path")
          .data(group(I, i => Z[i]))
          .join("path")
            .style("mix-blend-mode", mixBlendMode)
            .attr("stroke", ([z]) => {
                if(z === selectedCommunity) return selectedColor
                else return neutralColor
            })
            .attr("stroke-width", ([z]) => {
                if(z === selectedCommunity) return "2"
                else return "1.5"
            })
            .attr("d", ([, I]) => line(I));

        const dot = svgEl.append("g")
            .attr("display", "none");
      
        dot.append("circle")
            .attr("r", 2.5);
      
        dot.append("text")
            .attr("font-family", "sans-serif")
            .attr("font-size", 10)
            .attr("text-anchor", "middle")
            .attr("y", -8);

        function pointermoved(event) {
            const [xm, ym] = pointer(event);
            const i = least(I, i => Math.hypot(xScale(X[i]) - xm, yScale(Y[i]) - ym)); // closest point
            path.style("stroke", ([z]) => Z[i] === z ? passiveSelectedColor : null)
                .filter(([z]) => Z[i] === z).raise();
            dot.attr("transform", `translate(${xScale(X[i])},${yScale(Y[i])})`);
            if (T) dot.select("text").text(T[i]);

            svgEl.property("value", O[i]).dispatch("input", {bubbles: true});
            }
        
            function pointerentered() {
            path.style("mix-blend-mode", mixBlendMode).style("stroke", null);
            dot.attr("display", null);
            }
        
            function pointerleft() {
            path.style("mix-blend-mode", mixBlendMode).style("stroke", null);
            dot.attr("display", "none");
            svgEl.node().value = null;
            svgEl.dispatch("input", {bubbles: true});
            }
        
        return Object.assign(svgEl.node(), {value: null});
    }

}

export default LineChart