import React, { useEffect, useState } from 'react';

import * as con from "../Constants"
import DataDownloader from '../components/data/DataDownloader';
import { getApplicationParameter } from "../store/actions/applicationParameters";
import { Link } from "react-router-dom"


// static
import logoSigeti from "../static/img/sigeti.png"

function DataDownload() {   

    let isOnline = getApplicationParameter(con.IS_ONLINE)

    return (
        <div className='column-container'>
            <div className='column-container'>
                <div className='row-container'>
                <div style={{height : "65px", width:"120px"}}>
                    <Link to={con.PATH_HOME}>
                    <img src={logoSigeti} height="100%" alt="logo sigeti"/>
                    </Link>
                </div>            
                <h2>Descarga de datos</h2>
                </div>
            </div>
            {isOnline ? <DataDownloader /> : <p>Funcionalidad de descarga no disponible para modo offline</p>}
        </div>
    );
}

export default DataDownload