import React, { useEffect, useMemo, useRef } from 'react'


import DivergentVerticalBarChart  from '../../containers/d3Charts/DivergentVerticalBarChart'

import * as con from "../../Constants"
import { RefApplicationParameterHook } from '../hooks/ApplicationParamatersHook'
import { getCommunityByID} from '../../utils/dataFunctions'
import CommunityDataLoader from '../data/CommunityDataLoader'
import AgeSexBarChartByTerritory from './AgeSexBarChartByTerritory'
import { RIGHT } from '../../containers/d3Charts/chartConstants'



function AgeSexBarChartByCommunity({includeCommunityInTitle}) {



        // Object Reference
        const objectReference = useRef()

        
        // Display Mode
        const displayMode = RefApplicationParameterHook(con.DISPLAY_MODE)

        // Selected Community and Territory
        const communityId = RefApplicationParameterHook(con.SELECTED_COMMUNITY_ID)

        // Initial Parameters
        const  parameters = useMemo(() => {return({ y: d => d[con.AGE_GROUP],
                                title : includeCommunityInTitle ? `Edades de Hombres y Mujeres en ${communityId === null ? "": getCommunityByID(communityId)[con.COMMUNITY_NAME]}` 
                                            : "Edades de Hombres y Mujeres",
                                sorted : false,
                                marginRight : 165,
                                marginLeft : 15,
                                xLeft: d => d[con.TOTAL_MEN],
                                xRight: d => d[con.TOTAL_WOMEN],
                                leftColor : "var(--men-color)",
                                rightColor : "var(--women-color)",
                                xLeftLabel : "Hombres",
                                xRightLabel : "Mujeres",
                                yLabel : "Grupo Etario",
                                yAxisPosition : RIGHT,
                                numRows : 1,
        })},[communityId, includeCommunityInTitle] )


       
        // Territory data
        const data = CommunityDataLoader(con.DATASET_AGE_DISTRIBUTION)


        useEffect(() => {
            
            if(data.length > 0 && communityId !== con.NO_COMMUNITY_SELECTED) 
                DivergentVerticalBarChart.build(objectReference, parseData(data), parameters, displayMode)

        }, [data, communityId, parameters, displayMode])

        
        

        return (
            <div style={{width : "100%"}}>
                {data.length > 0 && <svg ref={objectReference} />}
            </div>
        );

}

AgeSexBarChartByTerritory.defaultProps = {
    includeCommunityInTitle : false
}


const parseData = (data) =>
{

    if(data.length === 0)
        return([])

    
    data.sort((a,b) => a[con.AGE_START] - b[con.AGE_START])
    

    return(data)


}

export default AgeSexBarChartByCommunity